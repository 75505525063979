import React, { useRef } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { QUERY_EXPERIMENTS } from 'client/app/apps/experiments/gql/queries';
import {
  Entity,
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import DataComponent from 'client/app/apps/experiments/Tutorials/TutorialsListDataComponent';
import { ContentType, ExperimentsQueryVariables } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { PageInfo } from 'common/server/graphql/pagination';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { EntityCardSkeletonList } from 'common/ui/components/EntityCard';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';
import SearchField from 'common/ui/components/SearchField';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

export function Tutorials() {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [searchQuery, setSearchQuery] = useStateWithURLParams({
    paramName: 'search',
    paramType: 'string',
    defaultValue: '',
  });

  const isEnabledQuickStartActivation = useFeatureToggle('QUICK_START_ACTIVATION');

  return (
    <ContainerWithIntersectionBar
      scrollableRef={scrollableContainerRef}
      headerLeftContent={
        isEnabledQuickStartActivation && <Typography variant="h2">Tutorials</Typography>
      }
      headerRightContent={
        <SearchField
          label="Search"
          defaultValue={searchQuery}
          onQueryChange={setSearchQuery}
        />
      }
      content={
        <TutorialList
          scrollableContainerRef={scrollableContainerRef}
          searchQuery={searchQuery}
        />
      }
      dense
    />
  );
}

type Props = {
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
  searchQuery?: string;
};

function TutorialList({ scrollableContainerRef, searchQuery }: Props) {
  const isEnabledQuickStartActivation = useFeatureToggle('QUICK_START_ACTIVATION');

  const variables: ExperimentsQueryVariables = {
    search: searchQuery,
    contentSource: ContentType.EXAMPLE,
  };

  const experimentsQuery = useQuery(QUERY_EXPERIMENTS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const pageInfo = experimentsQuery.data?.experiments.pageInfo as PageInfo | undefined;
  const hasNextPage = usePagination({
    entity: 'experiments',
    pageInfo,
    fetchMore: experimentsQuery.fetchMore,
    dependencies: [],
    scrollableRef: scrollableContainerRef,
    isInitialLoading: experimentsQuery.loading,
    variables,
  });

  return (
    <>
      <RenderQuery
        query={experimentsQuery}
        renderData={DataComponent}
        renderNoData={NoEntitiesMessage}
        additionalNoDataProps={{
          entityName: 'examples' as Entity,
          messageType: MessageType.NO_FILTER_RESULTS,
          searchQuery,
        }}
        emptyCondition={data => data.experiments.items.length === 0}
        loadingComponent={
          isEnabledQuickStartActivation ? EntityCardSkeletonList : undefined
        }
      />
      {hasNextPage && (
        <LoadingContainer>
          <CircularProgress size={24} />
        </LoadingContainer>
      )}
    </>
  );
}

const LoadingContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
