import React, { useContext } from 'react';

import { useMutation } from '@apollo/client';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { MUTATION_UPDATE_EXECUTION_STAGE_COMMENT } from 'client/app/api/gql/mutations';
import { DispenserButtonSet } from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/buttons/DispenserButtonSet';
import { LiquidHandlerButtonSet } from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/buttons/LiquidHandlerButtonSet';
import { ManualButtonSet } from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/buttons/ManualButtonSet';
import CommentContext, {
  CommentContextProvider,
} from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/CommentContext';
import ExecutionStageCard from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/ExecutionStageCard';
import {
  EXECUTION_DETAILS_EVENTS,
  executionDetailsResourcesExpandedEvent,
} from 'client/app/apps/execution-details/ExecuteTab/metrics/executionDetailsMetrics';
import Reagents from 'client/app/apps/execution-details/ExecuteTab/Reagents/Reagents';
import { useDownloadReagents } from 'client/app/apps/execution-details/ExecuteTab/Reagents/useDownloadReagents';
import {
  FoldedSection,
  FoldedSections,
  ResourcesContainer,
} from 'client/app/apps/execution-details/ExecuteTab/Resources';
import useDownloadInstructions from 'client/app/apps/execution-details/ExecuteTab/useDownloadInstructions';
import useMarkExecutionStageAsComplete from 'client/app/apps/execution-details/ExecuteTab/useMarkExecutionStageComplete';
import useScheduleExecutionStage from 'client/app/apps/execution-details/ExecuteTab/useScheduleExecutionStage';
import { Execution, ExecutionStage } from 'client/app/apps/execution-details/types';
import Labware from 'client/app/components/Labware/Labware';
import useFormattedLabware from 'client/app/components/Labware/useFormattedLabware';
import { ExecutionModeEnum, Reagent } from 'client/app/gql';
import usePlateTypes from 'client/app/hooks/usePlateTypes';
import { Deck } from 'common/types/mix';
import Colors from 'common/ui/Colors';

const EMPTY_REAGENTS_LIST = [] as Reagent[];

type Props = {
  execution: Execution;
  deck: Deck;
};

export default function ExecuteTab({ execution, deck }: Props) {
  /**
   * TODO: we should not use simulation object here as it incorporates a lot of
   * unnecessary payload being transfered through the network.
   * Instead we should figure out what parts of the simulation are used and for what
   * purposes and create dedicated GrapqhQL fields serving those purposes
   */
  const plateTypes = usePlateTypes();
  const formattedLabware = useFormattedLabware(
    deck,
    plateTypes,
    execution.simulation.tipUsage,
  );
  const downloadReagents = useDownloadReagents(execution.simulation.name);
  const reagents = execution.simulation.reagents ?? EMPTY_REAGENTS_LIST;

  const onLabwareExpand = () => {
    executionDetailsResourcesExpandedEvent(
      EXECUTION_DETAILS_EVENTS.RESOURCES_LABWARE_EXPANDED,
      execution.stages,
    );
  };

  const onReagentsExpand = () => {
    executionDetailsResourcesExpandedEvent(
      EXECUTION_DETAILS_EVENTS.RESOURCES_REAGENTS_EXPANDED,
      execution.stages,
    );
  };

  return (
    <Container>
      <Stages>
        <Typography variant="h1" sx={{ mb: 5 }}>
          Stages
        </Typography>
        <CardStack>
          {execution.stages.map(stage => {
            return (
              <StageCardWrapper key={stage.id}>
                {stage.deviceExecutionMode.mode === ExecutionModeEnum.Manual ? (
                  <ManualStageCard stage={stage} execution={execution} />
                ) : stage.deviceExecutionMode.mode === ExecutionModeEnum.External ? (
                  <DispenserStageCard stage={stage} execution={execution} />
                ) : stage.deviceExecutionMode.mode === ExecutionModeEnum.SynthaceHub ? (
                  <LiquidHandlerStageCard stage={stage} execution={execution} />
                ) : null}
              </StageCardWrapper>
            );
          })}
        </CardStack>
      </Stages>
      <ResourcesContainer>
        <Typography variant="h2">Resources</Typography>
        <FoldedSections>
          <FoldedSection label="Labware" onExpand={onLabwareExpand}>
            <Labware labware={formattedLabware} />
          </FoldedSection>
          <FoldedSection label="Reagents" onExpand={onReagentsExpand}>
            <Reagents reagents={reagents} onDownload={downloadReagents} />
          </FoldedSection>
        </FoldedSections>
      </ResourcesContainer>
    </Container>
  );
}

function StageCardWrapper({ children }: { children: React.ReactNode }) {
  const updateCommentMutationTuple = useMutation(MUTATION_UPDATE_EXECUTION_STAGE_COMMENT);
  return (
    <CommentContextProvider updateCommentMutationTuple={updateCommentMutationTuple}>
      {children}
    </CommentContextProvider>
  );
}

type StageCardProps = {
  stage: ExecutionStage;
  execution: Execution;
};

function ManualStageCard({ stage, execution }: StageCardProps) {
  const [markExecutionStageAsComplete, { markingAsComplete }] =
    useMarkExecutionStageAsComplete();

  const { openDialogue } = useContext(CommentContext);

  return (
    <ExecutionStageCard
      executionStage={stage}
      buttonSection={
        <ManualButtonSet
          status={stage.status}
          simulationId={stage.simulationStage.simulationId}
          onMarkAsComplete={async () => {
            await markExecutionStageAsComplete(execution.id, stage.id);
            await openDialogue({
              existingComment: null,
              orderNum: stage.simulationStage.orderNum + 1,
              executionStageId: stage.id,
              readonly: false,
            });
          }}
          markingAsComplete={markingAsComplete}
        />
      }
    />
  );
}

function DispenserStageCard({ stage, execution }: StageCardProps) {
  const downloadInstructionsForTask = useDownloadInstructions();
  const [markExecutionStageAsComplete, { markingAsComplete }] =
    useMarkExecutionStageAsComplete();

  const { openDialogue } = useContext(CommentContext);

  if (stage.tasks.length !== 1) {
    throw new Error('Stage with external execution mode should have exactly one task');
  }

  return (
    <ExecutionStageCard
      executionStage={stage}
      showTasks
      buttonSection={
        <DispenserButtonSet
          status={stage.status}
          onMarkAsComplete={async () => {
            await markExecutionStageAsComplete(execution.id, stage.id);
            await openDialogue({
              existingComment: null,
              orderNum: stage.simulationStage.orderNum + 1,
              executionStageId: stage.id,
              readonly: false,
            });
          }}
          onDownloadInstructions={async () => {
            await downloadInstructionsForTask(
              execution.id,
              execution.simulation.id,
              execution.simulation.name,
              stage.id,
              stage.simulationStage.orderNum,
              stage.tasks[0].simulationTask!,
              true,
            );
          }}
          markingAsComplete={markingAsComplete}
        />
      }
    />
  );
}

function LiquidHandlerStageCard({ stage, execution }: StageCardProps) {
  const downloadInstructionsForTask = useDownloadInstructions();
  const [scheduleExecutionStage, { scheduling }] = useScheduleExecutionStage();

  return (
    <ExecutionStageCard
      executionStage={stage}
      showTasks
      buttonSection={
        <LiquidHandlerButtonSet
          isScheduled={stage.scheduledAt !== null}
          // If there is a single task and that task is for the Gilson,
          // we need to show the download instructions button, as those
          // payloads can be executed outside of SynthaceHub
          showDownloadInstructions={
            stage.tasks.length === 1 &&
            stage.tasks[0].simulationTask?.device?.model.anthaLangDeviceClass ===
              'GilsonPipetMax'
          }
          onSchedule={async () => {
            await scheduleExecutionStage(stage.id);
          }}
          onDownloadInstructions={async () => {
            await downloadInstructionsForTask(
              execution.id,
              execution.simulation.id,
              execution.simulation.name,
              stage.id,
              stage.simulationStage.orderNum,
              stage.tasks[0].simulationTask!,
              false,
            );
          }}
          scheduling={scheduling}
        />
      }
    />
  );
}

const Container = styled('section')({
  display: 'grid',
  gridTemplateColumns: '7fr 3fr',

  height: '100%',
});

const Stages = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(7),
  backgroundColor: Colors.WHITE,
}));

const CardStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(6),
  flexGrow: 1,
  height: 0,
  overflowY: 'auto',
}));
