import React from 'react';

import { styled } from '@mui/material/styles';

import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import { ExperimentCard } from 'client/app/components/cards/ExperimentCard';
import { ExperimentsQuery } from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';

type Props = {
  data: ExperimentsQuery;
  onClick?: (experimentId: ExperimentId) => void;
  disabledExperimentIds?: ExperimentId[];
  highlightedExperiments?: ExperimentId[];
};

const DataComponent = ({
  data,
  onClick,
  disabledExperimentIds,
  highlightedExperiments,
}: Props) => {
  const currentUser = useUserProfile();
  const experiments = data.experiments.items || [];

  const isDisabled = (experimentId: ExperimentId) => {
    return disabledExperimentIds ? disabledExperimentIds.includes(experimentId) : false;
  };
  const isHighlighted = (experimentId: ExperimentId) => {
    return highlightedExperiments ? highlightedExperiments.includes(experimentId) : false;
  };

  return (
    <List>
      {experiments.map(experiment =>
        onClick ? (
          <ExperimentCard
            key={experiment.id}
            experiment={experiment}
            onClick={onClick}
            isDisabled={isDisabled(experiment.id)}
            isSelected={isHighlighted(experiment.id)}
          />
        ) : (
          <ExperimentCard
            key={experiment.id}
            experiment={experiment}
            isDisabled={isDisabled(experiment.id)}
            isSelected={isHighlighted(experiment.id)}
            isLink
            allowDelete={experiment.createdBy.id === currentUser?.id}
          />
        ),
      )}
    </List>
  );
};

const List = styled('div')(({ theme }) => ({
  ...experimentsStyles(theme).list,
}));

export default DataComponent;
