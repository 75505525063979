import React from 'react';

import { ApolloError } from '@apollo/client';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { isLocalOrNinjaEnvironment } from 'common/ui/lib/envs';

type Props = {
  error: Error | ApolloError;
  onRetry?: () => void;
};

function ErrorMessage({ error }: Pick<Props, 'error'>) {
  let networkError = error;
  if (error instanceof ApolloError) {
    // An ApolloError instance either contains a `networkError` or a list of graphql errors.
    // We handle these network errors below, along with non-Apollo errors.
    if (error.networkError) {
      networkError = error.networkError;
    } else {
      return (
        <div>
          {error.graphQLErrors.map(error => (
            <Typography key={error.message}>{error.message}</Typography>
          ))}
        </div>
      );
    }
  }

  return <div>{networkError.toString()}</div>;
}

export default function GraphQLErrorPanel({ onRetry, error }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.header}>
        Could not fetch data.
        {error instanceof ApolloError && error.networkError
          ? ' Please check your network connection.'
          : ''}
      </Typography>
      {isLocalOrNinjaEnvironment() && <ErrorMessage error={error} />}
      {onRetry && (
        <Button
          className={classes.retryButton}
          onClick={() => onRetry()}
          color="secondary"
          variant="outlined"
        >
          Retry
        </Button>
      )}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  container: {
    padding: theme.spacing(5),
    fontWeight: 400,
    textAlign: 'center',
    overflow: 'auto',
    margin: 'auto',
    // As this component can be displayed inside other compoents,
    // make sure it doesn't get to wide if the error message is long.
    maxWidth: '300px',
    // Restore some defaults to enable text wrapping
    // in case this gets rendered inside a component that prevents it.
    overflowWrap: 'initial',
    whiteSpace: 'initial',
  },
  header: {
    marginBottom: theme.spacing(5),
  },
  retryButton: {
    margin: theme.spacing(6),
  },
}));
