import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Download from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import {
  DownloadInstructionsButton,
  MarkAsCompleteButton,
} from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/buttons/button-styles';
import { ExecutionStatusEnum } from 'client/app/gql';

type Props = {
  status: ExecutionStatusEnum | null;
  onDownloadInstructions: () => Promise<void>;
  onMarkAsComplete: () => Promise<void>;
  markingAsComplete: boolean;
};

const CIRCULAR_PROGRESS_CONTAINER_SIZE = 20;

export function DispenserButtonSet({
  status,
  onDownloadInstructions,
  onMarkAsComplete,
  markingAsComplete,
}: Props) {
  const isExecutionInProgress = status === ExecutionStatusEnum.EXECUTION_IN_PROGRESS;
  const isExecutionSuccessful = status === ExecutionStatusEnum.EXECUTION_SUCCESS;
  const disabled = !isExecutionInProgress || isExecutionSuccessful || markingAsComplete;

  const markAsCompleteButton = (
    <MarkAsCompleteButton
      color="secondary"
      size="medium"
      variant="extended"
      disabled={disabled}
      onClick={onMarkAsComplete}
    >
      {markingAsComplete ? (
        <ScaledCircularProgress size={CIRCULAR_PROGRESS_CONTAINER_SIZE} color="inherit" />
      ) : (
        <CheckCircleOutlineIcon fontSize="small" />
      )}
      Mark as complete
    </MarkAsCompleteButton>
  );

  return (
    <>
      <DownloadInstructionsButton
        variant="secondary"
        color={isExecutionInProgress || isExecutionSuccessful ? 'inherit' : 'primary'}
        startIcon={<Download fontSize="small" />}
        onClick={onDownloadInstructions}
      >
        Download instructions
      </DownloadInstructionsButton>
      {disabled && !markingAsComplete ? (
        <Tooltip
          title={
            !isExecutionSuccessful
              ? 'Instructions must be downloaded before the stage can be marked as complete'
              : 'The stage is already marked as complete'
          }
        >
          <span>{markAsCompleteButton}</span>
        </Tooltip>
      ) : (
        markAsCompleteButton
      )}
    </>
  );
}

// Scale the circular progress svg so that it is the same size as the <CheckCircleOutlineIcon/> (svg of 16.6833 x 16.6833)
const scale = (16.6833 / CIRCULAR_PROGRESS_CONTAINER_SIZE).toFixed(2);

const ScaledCircularProgress = styled(CircularProgress)({ scale });
