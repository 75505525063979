import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';

import { useQuery } from '@apollo/client';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { QUERY_EXPERIMENTS } from 'client/app/apps/experiments/gql/queries';
import { tutorialsOrdering } from 'client/app/apps/landing-page/tutorialsOrdering';
import { ContentType } from 'client/app/gql';
import { experimentsRoutes } from 'client/app/lib/nav/actions';
import Colors from 'common/ui/Colors';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';

type Side = 'left' | 'right';

export default function TutorialsSection() {
  const { loading, data, refetch, error } = useQuery(QUERY_EXPERIMENTS, {
    variables: {
      contentSource: ContentType.EXAMPLE,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const orderedTutorials = useMemo(() => {
    if (loading || error) {
      return undefined;
    }

    const result = [
      ...(data?.experiments.items.filter(exp => !!tutorialsOrdering[exp.name]) ?? []),
    ];

    result.sort((a, b) => tutorialsOrdering[a.name] - tutorialsOrdering[b.name]);

    return result.slice(0, 5);
  }, [data?.experiments.items, error, loading]);

  const { container, containerContent, viewport, viewportContent } = useAutoScroll();

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }

  if ((orderedTutorials?.length ?? 0) === 0) {
    return (
      <Typography variant="body1" color="textSecondary">
        No tutorials are currently available.
      </Typography>
    );
  }

  return (
    <TutorialsContainer {...container}>
      {containerContent}
      <TutorialsViewport {...viewport}>
        <TutorialsList>
          {!orderedTutorials ? (
            <>
              <TutorialSkeleton />
              <TutorialSkeleton />
              <TutorialSkeleton />
            </>
          ) : (
            orderedTutorials.map(({ id, name }) => (
              <TutorialCard
                key={id}
                id={id}
                to={experimentsRoutes.detail.getPath({ id })}
              >
                <TutorialName>{name}</TutorialName>
              </TutorialCard>
            ))
          )}
          {viewportContent}
        </TutorialsList>
      </TutorialsViewport>
    </TutorialsContainer>
  );
}

const AUTO_SCROLL_AREA = 50;

/**
 * Automatically scroll a scrollable element when the user hovers over the
 * left or right edge of its container.
 */
function useAutoScroll() {
  const [viewportEl, setViewportEl] = useState<HTMLDivElement | null>(null);
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null);
  const [autoScroll, setAutoScroll] = useState<Side>();

  const onContainerMouseMove = useCallback<MouseEventHandler>(
    e => {
      const { left, right, width } = e.currentTarget.getBoundingClientRect();

      if (viewportEl) {
        if (e.clientX - left <= AUTO_SCROLL_AREA && viewportEl.scrollLeft > 0) {
          setAutoScroll('left');
        } else if (
          right - e.clientX <= AUTO_SCROLL_AREA &&
          viewportEl.scrollLeft + width < viewportEl.scrollWidth
        ) {
          setAutoScroll('right');
        } else {
          setAutoScroll(undefined);
        }
      }
    },
    [viewportEl],
  );

  const onContainerMouseLeave = useCallback<MouseEventHandler>(
    () => setAutoScroll(undefined),
    [],
  );

  useEffect(() => {
    if (autoScroll) {
      const interval = window.setInterval(() => {
        if (viewportEl) {
          viewportEl.scrollLeft += autoScroll === 'left' ? -5 : 5;
        }
      }, 10);

      return () => window.clearInterval(interval);
    }

    return () => {};
  }, [autoScroll, viewportEl]);

  const [visibleEdges, setVisibleEdges] = useState<Record<Side, boolean>>({
    left: true,
    right: true,
  });

  useEffect(() => {
    if (containerEl) {
      const observer = new IntersectionObserver(
        entries => {
          for (const entry of entries) {
            setVisibleEdges(v => ({
              ...v,
              [entry.target.getAttribute('data-side') as Side]: entry.isIntersecting,
            }));
          }
        },
        { root: containerEl },
      );

      for (const elem of containerEl.querySelectorAll('[data-side]')) {
        observer.observe(elem);
      }

      return () => observer.disconnect();
    }

    return () => {};
  }, [containerEl]);

  return {
    autoScroll,
    container: {
      onMouseMove: onContainerMouseMove,
      onMouseLeave: onContainerMouseLeave,
      ref: setContainerEl,
    },
    viewport: {
      ref: setViewportEl,
    },
    visibleEdges,
    containerContent: (
      <>
        {autoScroll === 'left' && !visibleEdges.left ? (
          <AutoScrollIndicator side="left">
            <ArrowLeft />
          </AutoScrollIndicator>
        ) : null}
        {autoScroll === 'right' && !visibleEdges.right ? (
          <AutoScrollIndicator side="right">
            <ArrowRight />
          </AutoScrollIndicator>
        ) : null}
        <AutoScrollGradient side="left" visible={!visibleEdges.left} />
        <AutoScrollGradient side="right" visible={!visibleEdges.right} />
      </>
    ),
    viewportContent: (
      <>
        <Edge side="left" data-side="left" />
        <Edge side="right" data-side="right" />
      </>
    ),
  };
}

const TutorialSkeleton = () => (
  <Skeleton width={400} height={200} variant="rectangular" />
);

const TutorialsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  placeItems: 'stretch',
  position: 'relative',
  marginTop: `-${theme.spacing(4)}`,
}));

const TutorialsViewport = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

const TutorialsList = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(5),
  margin: 0,
  display: 'grid',
  gap: theme.spacing(6),
  grid: `auto / auto`,
  gridAutoFlow: 'column',
  width: 'fit-content',
}));

const TutorialCard = styled(Link)(({ theme }) => ({
  width: 400,
  height: 200,
  display: 'grid',
  placeContent: 'start',
  padding: theme.spacing(6),
  background: Colors.WHITE,
  textDecoration: 'none',
  color: theme.palette.text.primary,
  borderRadius: theme.spacing(3),
  boxShadow: `0px 4px 12px 0px rgba(0, 0, 0, 0.30)`,
}));

const TutorialName = styled('span')(({ theme }) => ({
  textWrap: 'balance',
  ...theme.typography.subtitle1,
  fontSize: 16,
}));

const Edge = styled('div')<{ side: Side }>(({ side }) => ({
  position: 'absolute',
  width: '1px',
  top: 0,
  bottom: 0,
  [side]: 0,
}));

const AutoScrollIndicator = styled(Edge)({
  display: 'grid',
  placeItems: 'center',
  background: 'rgb(0,0,0,0.1)',
  opacity: 1,
  transition: 'opacity 0.15s ease-in',
  width: AUTO_SCROLL_AREA,
  zIndex: 2,
  '@starting-style': {
    opacity: 0,
  },
});

const AutoScrollGradient = styled(Edge)<{ visible: boolean }>(({ side, visible }) => ({
  background: `linear-gradient(to ${side}, rgba(0,0,0,0), rgba(0,0,0,.08))`,
  opacity: visible ? 1 : 0,
  transition: 'opacity 0.1s ease-in',
  width: 25,
  zIndex: 1,
  pointerEvents: 'none',
}));
