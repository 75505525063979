import React from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { QUERY_RECENT_WORKFLOWS } from 'client/app/api/gql/queries';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import { WorkflowSourceEnum } from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { workflowRoutes } from 'client/app/lib/nav/actions';
import { formatDateTime } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';

export default function RecentSection() {
  const userProfile = useUserProfile();

  const { loading, error, data, refetch } = useQuery(QUERY_RECENT_WORKFLOWS, {
    variables: {
      userId: userProfile?.id,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !userProfile,
  });

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  if ((data?.workflows.items.length ?? 0) === 0) {
    return (
      <NoEntitiesMessage
        entityName="workflows"
        messageType={MessageType.NO_FILTER_RESULTS}
      />
    );
  }

  return (
    <Workflows>
      {data?.workflows.items.map(wf => (
        <WorkflowItem
          to={getWorkflowLink(wf.source).getPath({ workflowId: wf.id })}
          key={wf.id}
        >
          <Title variant="h5" color="textPrimary">
            {wf.name}
          </Title>
          <LastModified variant="caption" color="textSecondary">
            {formatDateTime(new Date(wf.lastModifiedAt))}
          </LastModified>
        </WorkflowItem>
      ))}
    </Workflows>
  );
}

function getWorkflowLink(source: WorkflowSourceEnum) {
  switch (source) {
    case WorkflowSourceEnum.CHERRY_PICKER:
      return workflowRoutes.openInCherryPicker;
    case WorkflowSourceEnum.FORM_EDITOR:
      return workflowRoutes.editForm;
    default:
      return workflowRoutes.openInWorkflowBuilder;
  }
}

const Workflows = styled('div')({
  listStyleType: 'none',
});

const Title = styled(Typography)({
  gridArea: 'title',
});

const LastModified = styled(Typography)({
  gridArea: 'modified',
});

const WorkflowItem = styled(Link)(({ theme }) => ({
  padding: theme.spacing(5, 0),
  display: 'grid',
  gridTemplate: `
    'title status' auto
    'modified status' auto / 1fr auto
  `,
  flexDirection: 'column',
  gap: theme.spacing(3),

  '&:not(:last-child)': {
    borderBottom: `1px solid ${Colors.GREY_20}`,
  },
  textDecoration: 'none',
  [`&:hover ${Title}`]: {
    textDecoration: 'underline',
    textDecorationColor: Colors.GREY_40,
    textUnderlineOffset: 4,
  },
}));
