import { useMemo } from 'react';

import { WritableDraft } from 'immer/dist/internal';
import entries from 'lodash/entries';

import { OutputPreviewPanelState } from 'client/app/apps/workflow-builder/output-preview/types';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { isDefined } from 'common/lib/data';
import { formatMeasurementObj, formatWellPosition, roundNumber } from 'common/lib/format';
import { alphanumericCompare } from 'common/lib/strings';
import { FilterMatrix, Liquid } from 'common/types/bundle';
import { Measurement } from 'common/types/mix';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';

type Row = {
  id: string; // id will be the liquid 'name' + idx on interation
  name: string;
  location?: string;
  volume?: string;
  replicate?: string;
  cells?: Cell[];
};

type Cell = {
  id: string; // id will be the parent 'id' and used for uniqueness when iterating.
  content: string;
};

/**
 * Returns true if the input type is a Liquid
 */
export function isLiquidType(input: Liquid | FilterMatrix): input is Liquid {
  return 'id' in input;
}

export function useOutputPreviewTable(
  outputLiquids: (Liquid | FilterMatrix)[],
  plateName: string | undefined,
): {
  rows: Row[] | undefined;
  columnHeaders: string[] | undefined;
} {
  return useMemo(() => {
    const filteredOutputLiquids = outputLiquids.filter(
      liquid => !plateName || liquid.position?.plateName === plateName,
    );

    const subComponents = filteredOutputLiquids
      .flatMap(liquid => (isLiquidType(liquid) ? entries(liquid.subComponents) : []))
      .filter(isDefined);

    const formatSubcomponentString = (subComponent: [string, Measurement]) => {
      return `${subComponent[0]} (${subComponent[1].unit})`;
    };
    // The uniqueSubComponents are effectively going to be some of the column headers in our table.
    // Each will be the unique combination of subomponent name and concentration unit.
    // We don't want to display duplicates, so we group them here, and use this as a reference
    // for ranging over our liquids.
    const uniqueSubComponents = new Set<string>(
      subComponents.map(formatSubcomponentString).sort(alphanumericCompare),
    );

    // Metadata is additional data (non-subcomponent related) added to the table
    const metataData = new Set<string>();

    const rows: Row[] = [];

    filteredOutputLiquids.forEach((liquid, idx) => {
      const cells: Cell[] = [];
      if (isLiquidType(liquid)) {
        const subComponentNamesAndConcentrations = new Map(
          entries(liquid.subComponents).map(subComponent => {
            return [formatSubcomponentString(subComponent), subComponent[1]];
          }),
        );

        uniqueSubComponents.forEach(subComponentNameAndConcentration => {
          // If this uniqueSubComponents is not in the current liquid, create an
          // "empty" cell.
          if (!subComponentNamesAndConcentrations.has(subComponentNameAndConcentration)) {
            cells.push({
              id: `${liquid.id}-${subComponentNameAndConcentration}`,
              content: '-',
            });
          } else {
            const subComponentConcentration = subComponentNamesAndConcentrations.get(
              subComponentNameAndConcentration,
            );
            if (subComponentConcentration) {
              cells.push({
                id: `${liquid.id}-${subComponentNameAndConcentration}`,
                content: roundNumber(subComponentConcentration.value),
              });
            }
          }
        });
      } else {
        const resin = liquid.metaData?.find(tag => tag.label === 'Resin Type');
        const resinName =
          resin && `value_string` in resin ? `${resin.value_string}` : undefined;
        if (resinName) {
          metataData.add('Resin');

          cells.push({
            id: `${liquid.name}-${resinName}`,
            content: resinName,
          });
        }
      }

      const replicate = liquid?.metaData?.find(tag => tag.label === 'Replicate');
      const replicateValue =
        replicate && `value_float` in replicate ? `${replicate.value_float}` : undefined;

      rows.push({
        id: `${liquid.name}-${idx}`,
        location: liquid.position?.wellCoords
          ? formatWellPosition(liquid.position.wellCoords.y, liquid.position.wellCoords.x)
          : undefined,
        name: liquid.name ?? '',
        volume: liquid.volume
          ? formatMeasurementObj(liquid.volume).replace('ul', '')
          : undefined,
        replicate: replicateValue,
        cells: cells,
      });
    });

    return { rows, columnHeaders: [...uniqueSubComponents, ...metataData] };
  }, [outputLiquids, plateName]);
}

export function useElementParameterAndColorsForOutputs(
  elementId: string,
  parameterName: string,
): {
  elementInstanceName: string;
  parameterDisplayName: string;
  liquidColors: LiquidColors;
} {
  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);

  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);
  const element = elementInstances.find(el => el.Id === elementId);
  const parameter = element?.element.outputs.find(param => param.name === parameterName);
  if (!element || !parameter) {
    throw new Error(
      `Could not find element with id: ${elementId} and parameter name: ${parameterName}`,
    );
  }
  return {
    elementInstanceName: element.name,
    parameterDisplayName: parameter.configuration?.displayName ?? parameter.name,
    liquidColors,
  };
}

export function resetOutputPreviewState(state: WritableDraft<OutputPreviewPanelState>) {
  state.entityView = undefined;
  state.outputType = undefined;
  state.selectedOutputParameterName = undefined;
}
