import React, { ReactNode } from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import stopPropagation from 'common/lib/stopPropagation';
import { ContentSourceType } from 'common/types/contentSource';
import { Device } from 'common/types/device';
import Colors from 'common/ui/Colors';
import StatusTick from 'common/ui/components/DeviceCard/StatusTick';
import Tooltip from 'common/ui/components/Tooltip';
import { useCardClick } from 'common/ui/hooks/cardHooks';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { BookAndBulbIcon } from 'common/ui/icons/BookAndBulbIcon';

type Props = {
  device: Device;
  selected?: boolean;
  disabled?: boolean;
  disabledWithReason?: string;
  disabledButtonCopy?: string;
  onSelect?: (id: string) => void;
  renderActions?: (device: Device) => ReactNode;
  showSelectionStatus?: boolean;
  small?: boolean;
  description?: string;
  contentSource?: string;
};

/**
 * A card that shows the details of a device and allows selecting it.
 */
export default function DeviceCard(props: Props) {
  const classes = useDeviceStyles();
  const {
    device,
    renderActions,
    selected,
    onSelect,
    showSelectionStatus,
    small,
    disabled,
    disabledWithReason,
    disabledButtonCopy,
    description,
    contentSource,
  } = props;

  return (
    <DeviceCardBase
      id={device.id}
      title={device.name}
      image={<DeviceImage device={device} cssClassName={classes.devicePic} />}
      body={description ?? device.model}
      devicePluginVersion={device.devicePluginVersion}
      actions={renderActions?.(device)}
      selected={selected}
      onSelect={onSelect}
      showSelectionStatus={showSelectionStatus}
      small={small}
      disabled={disabled}
      disabledWithReason={disabledWithReason}
      disabledButtonCopy={disabledButtonCopy}
      substyles={classes}
      contentSource={contentSource}
      accessibleDevices={
        device.accessibleDevices && (
          <div className={classes.accessibleDeviceThumbnails}>
            {/*
            Only show up to 2 devices. In practice there are no use cases for
            3+ accessible devices. If we have such use cases we should design
            what the UI should look like first and then adapt this code.
          */}
            {device.accessibleDevices.slice(0, 2).map(accessibleDevice => (
              <AccessibleDeviceThumbnail
                key={accessibleDevice.id}
                {...accessibleDevice}
              />
            ))}
          </div>
        )
      }
    />
  );
}

type PrefixCardProps = {
  id: string;
  title: string;
  image: JSX.Element;
  body: string;
  selected?: boolean;
  disabled?: boolean;
  onSelect?: (id: string) => void;
  actions?: ReactNode;
  showSelectionStatus?: boolean;
  small?: boolean;
};

/**
 * A card that is laid out and styled similarly to a DeviceCard but displays the provided title and
 * body instead.  When selected, the specified id will be provided.  This should be used for
 * displaying items alongside a list of devices that have some related piece of functionality, like
 * the generic upload parser.
 */
export function PrefixCard(props: PrefixCardProps) {
  const classes = useObjectStyles();
  return (
    <DeviceCardBase
      id={props.id}
      title={props.title}
      image={props.image}
      body={props.body}
      actions={props.actions}
      selected={props.selected}
      onSelect={props.onSelect}
      showSelectionStatus={props.showSelectionStatus}
      small={props.small}
      disabled={props.disabled}
      substyles={classes}
    />
  );
}

type DeviceCardBaseProps = {
  id: string;
  title: string;
  image: JSX.Element;
  body: string;
  contentSource?: string;
  devicePluginVersion?: string;
  accessibleDevices?: ReactNode;
  selected?: boolean;
  disabled?: boolean;
  disabledWithReason?: string;
  disabledButtonCopy?: string;
  onSelect?: (id: string) => void;
  actions?: ReactNode;
  showSelectionStatus?: boolean;
  small?: boolean;
  substyles: Record<'header' | 'title', string>;
};

/**
 * Base component that implements DeviceCard and PrefixCard.
 */
function DeviceCardBase(props: DeviceCardBaseProps) {
  const classes = useStyles();
  const {
    id,
    title,
    image,
    body,
    devicePluginVersion,
    actions,
    accessibleDevices,
    selected,
    onSelect,
    showSelectionStatus,
    small,
    disabled,
    disabledWithReason,
    disabledButtonCopy,
    substyles,
    contentSource,
  } = props;

  const { onClick, onDoubleClick } = useCardClick(id, onSelect);

  const handleCardClick = () => {
    if (disabled === true) return;
    onClick();
  };
  const handleCardDoubleClick = () => {
    if (disabled === true) return;
    onDoubleClick();
  };

  const exampleDevice = contentSource?.includes(ContentSourceType.EXAMPLE) ?? null;

  return (
    <Card
      className={cx(classes.container, { [classes.containerSmall]: small })}
      onClick={handleCardClick}
      onDoubleClick={handleCardDoubleClick}
    >
      <div className={cx(classes.header, substyles.header)}>
        <div className={cx(classes.exampleIconAndTitle)}>
          {exampleDevice && (
            <Tooltip title="Example Device">
              <div className={classes.exampleIcon}>
                <BookAndBulbIcon />
              </div>
            </Tooltip>
          )}
          <Tooltip title={title}>
            <Typography
              variant="h5"
              color="textPrimary"
              className={cx(classes.title, substyles.title)}
            >
              {title}
            </Typography>
          </Tooltip>
        </div>
        {showSelectionStatus && (
          <div className={classes.selectedStatus}>
            <StatusTick
              on={selected}
              disabled={disabled}
              disabledWithReason={disabledWithReason}
              labelOn={disabled && disabledButtonCopy ? disabledButtonCopy : 'Selected'}
              labelOff={disabled && disabledButtonCopy ? disabledButtonCopy : 'Select'}
            />
          </div>
        )}
      </div>
      <CardContent className={classes.content}>
        <div className={classes.imageAndName}>
          <div className={classes.devicePicContainer}>{image}</div>
          <Typography
            className={classes.name}
            gutterBottom
            variant="body1"
            color="textSecondary"
          >
            {body}
          </Typography>
        </div>
        {devicePluginVersion && (
          <Typography
            className={classes.devicePluginVersion}
            variant="body2"
            color="textSecondary"
          >
            Device Plugin version: {devicePluginVersion}
          </Typography>
        )}
        {accessibleDevices}
      </CardContent>
      {actions && (
        <CardActions
          className={classes.cardActionsMustWrap}
          classes={{ spacing: classes.actionsSpacing }}
          onClick={stopPropagation}
        >
          {actions}
        </CardActions>
      )}
    </Card>
  );
}

type DeviceImageProps = {
  device: Device;
  cssClassName: string;
};
function DeviceImage(props: DeviceImageProps) {
  const { device, cssClassName } = props;
  return device.imageUrl ? (
    <img src={device.imageUrl} className={cssClassName} alt={`${device.model} picture`} />
  ) : (
    <div />
  );
}

type AccessibleDeviceThumbnailProps = {
  name: string;
  imageUrl: string | null;
};
function AccessibleDeviceThumbnail(props: AccessibleDeviceThumbnailProps) {
  const classes = useStyles();
  const { name, imageUrl } = props;
  return (
    <div className={classes.accessibleDeviceThumbnail}>
      <div className={classes.accessibleDevicePictureWrapper}>
        {imageUrl && <img className={classes.accessibleDevicePicture} src={imageUrl} />}
      </div>
      {name}
    </div>
  );
}

const useDeviceStyles = makeStylesHook(theme => ({
  header: {
    backgroundColor: Colors.DEVICE_LIBRARY_CARD_HEADER,
  },
  title: {},
  devicePic: {
    display: 'block',
    width: '64px',
    maxHeight: '64px',
  },
  accessibleDeviceThumbnails: {
    marginTop: theme.spacing(5),
  },
}));

const useObjectStyles = makeStylesHook({
  header: {
    backgroundColor: Colors.BLUE_5,
  },
  title: {
    color: Colors.BLUE_80,
  },
});

export function getHumanReadableSource(source: string) {
  switch (source) {
    case ContentSourceType.EXAMPLE:
      return {
        humanReadableName: 'Synthace',
      };
    case ContentSourceType.USER_GENERATED:
      return {
        humanReadableName: 'Lab',
      };
    default:
      return {
        humanReadableName: 'Unknown',
      };
  }
}

const useStyles = makeStylesHook(theme => ({
  container: {
    wordBreak: 'break-word',
    margin: theme.spacing(0, 5, 5, 5),
    borderRadius: '4px',
    width: '362px',
    height: '232px',
    '&$containerSmall': {
      height: '232px',
    },
  },
  containerSmall: {
    // used in `container`
  },
  header: {
    height: '40px',
    padding: theme.spacing(5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },
  exampleIconAndTitle: {
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '1em',
  },
  selectedStatus: {
    cursor: 'pointer',
    flexShrink: 0,
    width: '99px',
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(5, 6),
  },
  imageAndName: {
    display: 'flex',
    alignItems: 'center',
  },
  devicePicContainer: {
    flex: 0,
    alignSelf: 'start',
    marginRight: theme.spacing(3),
  },
  devicePluginVersion: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
  },
  name: {
    flex: 1,
    // Some alternative cards (such as the generic data card) have multi-line text, and this allows
    // that to be written with newlines without having to worry about <br>s and such
    whiteSpace: 'pre-line',
  },
  cardActionsMustWrap: {
    '&.MuiCardActions-root': {
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      alignItems: 'stretch',
      minHeight: '40px',
      padding: 0,
      // Buttons should grow identically and have a divider.
      '& >* ': {
        flex: 1,
        borderRight: `1px solid ${Colors.GREY_20}`,
        borderRadius: 0,
      },
      // Last button should have no border.
      '& >:last-child': {
        borderRight: `0px`,
      },
    },
  },
  actionsSpacing: {
    '&.MuiCardActions-root >:not(:first-child)': {
      marginLeft: '0',
    },
  },
  accessibleDeviceThumbnail: {
    display: 'flex',
    alignItems: 'center',
  },
  accessibleDevicePicture: {
    maxWidth: '32px',
    maxHeight: '32px', // Maintain original aspect ratio
  },
  // So that's there's consistent space even for devices with no picture
  accessibleDevicePictureWrapper: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(3),
  },
  exampleIcon: {
    marginRight: '1em',
  },
}));
