export const tutorialsOrdering: Record<string, number> = {
  'Get Started with Core Elements: Basic Liquid Handling': 1,
  'Basic Quantification Assays: Generic, PicoGreen, Lowry, Bradford and Mycoplasma': 2,
  'Element Spotlight: Append': 3,
  'Element Spotlight: Make Mixtures': 4,
  'Element Spotlight: Mix Onto': 5,
  'Element Spotlight: Synchronise': 6,
  'Getting Started with Miniturized Purification': 7,
  '(Generic) Robocolumns tutorials - Experiment page': 8,
  'Introduction to Robocolumns DOE': 9,
  'Robocolumns DOE - Practice Exercises with Solutions': 10,
  'Get started with DOE on Synthace': 11,
  'Learn how to use DOE on Synthace using a Dispenser': 12,
  'DOE Workshop - Workflow and DOE design training': 13,
  'Element Spotlight: Calculate Stock Concentrations': 14,
  'Getting Started with ELISA on Synthace': 15,
  'Advanced Core Elements: Sandwich ELISA': 16,
  'Getting Started with Miniaturized Purification: Filter Plates': 17,
  // Uncomment the following lines to test locally:
  // TODO: Remove these when we can push the real tutorials to dev.

  'Capturing multi-step processes': 18,
  'Core Elements Preloaded Workflow Examples': 19,
  'Basic Quantification Assay Preloaded Workflow Examples': 20,
  'RoboColumns Preloaded Workflow Examples': 21,
  'Advanced Core Elements Preloaded Workflows': 22,
};
