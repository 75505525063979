import React from 'react';

import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { ExecutionTask } from 'client/app/apps/execution-details/types';
import { ExecutionTaskStatusEnum } from 'client/app/gql';
import { formatDateTime } from 'common/lib/format';
import Colors from 'common/ui/Colors';

function Task({ task: task }: { task: ExecutionTask }) {
  const taskTitle = task.simulationTask?.name;

  const [taskStatusCopy, taskStatusColor] = useStatusCopy(task.status);

  const showDate = shouldShowDate(task);
  const date = formatDateTime(new Date(task.lastModifiedAt));

  return (
    <Container>
      <Title variant="body1" color="textPrimary" title={taskTitle}>
        {taskTitle}
      </Title>
      <Dot color={taskStatusColor} />
      <Status variant="body1" color={taskStatusColor}>
        {taskStatusCopy}
      </Status>
      {showDate && (
        <Typography variant="body1" color="textPrimary">
          {date}
        </Typography>
      )}
    </Container>
  );
}

export default React.memo(Task);

//#region Styles

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(5),

  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(3),

  backgroundColor: Colors.GREY_10,
}));

const Title = styled(Typography)({
  flexGrow: 1,

  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const Dot = styled('div', { shouldForwardProp: prop => prop !== 'color' })<{
  color: React.CSSProperties['color'];
}>(({ color }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: color,
}));

const Status = styled(Typography, { shouldForwardProp: prop => prop !== 'color' })<{
  color: React.CSSProperties['color'];
}>(({ color }) => ({
  color,
  fontWeight: 600,
}));

//#endregion

function useStatusCopy(status: ExecutionTaskStatusEnum | null) {
  const { palette } = useTheme();

  switch (status) {
    case ExecutionTaskStatusEnum.Queued:
      return ['Not started', palette.text.secondary];
    case ExecutionTaskStatusEnum.Pending:
      return ['Pending', palette.text.secondary];
    case ExecutionTaskStatusEnum.Running:
      return ['In progress', palette.warning.main];
    case ExecutionTaskStatusEnum.Succeeded:
      return ['Succeeded', palette.success.main];
    case ExecutionTaskStatusEnum.Skipped:
      return ['Skipped', palette.text.secondary];
    case ExecutionTaskStatusEnum.Failed:
      return ['Failed', palette.error.main];
    case ExecutionTaskStatusEnum.ResumeFailed:
      return ['Resume failed', palette.error.main];
    case ExecutionTaskStatusEnum.Cancelled:
      return ['Cancelled', palette.error.main];
    default:
      return ['Unknown', palette.text.secondary];
  }
}

function shouldShowDate(task: ExecutionTask): boolean {
  return [
    ExecutionTaskStatusEnum.Succeeded,
    ExecutionTaskStatusEnum.Failed,
    ExecutionTaskStatusEnum.ResumeFailed,
    ExecutionTaskStatusEnum.Cancelled,
  ].includes(task.status);
}
