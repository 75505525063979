import React from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  isLiquidType,
  useOutputPreviewTable,
} from 'client/app/apps/workflow-builder/output-preview/outputPreviewUtils';
import { FilterMatrix, Liquid } from 'common/types/bundle';
import Colors from 'common/ui/Colors';

type OutputPreviewTableProps = {
  outputLiquids: (Liquid | FilterMatrix)[];
  plateName: string | undefined;
};

export default function OutputPreviewTable(props: OutputPreviewTableProps) {
  const { outputLiquids, plateName } = props;
  const { rows, columnHeaders } = useOutputPreviewTable(outputLiquids, plateName);
  const hasLocation = !!rows?.[0]?.location;
  const hasVolume = !!rows?.[0]?.volume;
  const hasReplicate = !!rows?.[0]?.replicate;
  // We only deal with Liquid or FilterMatrix, so if it's not a Liquid, we assume it's a FilterMatrix.
  const isLiquid = outputLiquids[0] && isLiquidType(outputLiquids[0]);
  return (
    <StyledTableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {hasLocation && <TableCell>Location</TableCell>}
            <TableCell>{isLiquid ? 'Liquid' : 'RoboColumn'} Name</TableCell>
            {hasVolume && <TableCell>Volume (ul)</TableCell>}
            {hasReplicate && <TableCell>Replicate</TableCell>}
            {columnHeaders?.map((columnHeader, idx) => {
              return <TableCell key={`${columnHeader}-${idx}`}>{columnHeader}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => {
            return (
              <TableRow key={row.id}>
                {hasLocation && <TableCell>{row.location}</TableCell>}
                <TableCell>{row.name}</TableCell>
                {hasVolume && <TableCell>{row.volume}</TableCell>}
                {hasReplicate && <TableCell>{row.replicate}</TableCell>}
                {row.cells?.map(cell => (
                  <TableCell key={cell.id}>{cell.content}</TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

const StyledTableContainer = styled(TableContainer)(({ theme: { spacing } }) => ({
  gridArea: 'content',
  border: `1px solid ${Colors.GREY_30}`,
  borderRadius: spacing(2),
}));
