import React, { useCallback, useRef } from 'react';

import { styled } from '@mui/material/styles';

import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';
import { ExampleWorkflow } from 'client/app/components/cards/ExampleWorkflowCard';
import CategoriesList from 'client/app/components/ExampleWorkflows/CategoriesList';
import ExampleWorkflowsDialog from 'client/app/components/ExampleWorkflows/Dialog';
import ExampleWorkflowsList from 'client/app/components/ExampleWorkflows/ExampleWorkflowsList';
import { HeaderWithDescription } from 'client/app/components/ExampleWorkflows/HeaderWithDescription';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import useDialog from 'common/ui/hooks/useDialog';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

export default function ExamplesScreen() {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [searchQuery, setSearchQuery] = useStateWithURLParams({
    paramName: 'search',
    paramType: 'string',
    defaultValue: '',
  });

  const [tagQuery, setTagQuery] = useStateWithURLParams({
    paramName: 'tag',
    paramType: 'string',
    defaultValue: '',
  });

  const [exampleWorkflowsDialog, openExampleWorkflowsDialog] =
    useDialog(ExampleWorkflowsDialog);

  const handleExampleWorkflowDetailsClick = useCallback(
    async (exampleWorkflow: ExampleWorkflow) => {
      await openExampleWorkflowsDialog({
        initialExampleWorkflow: exampleWorkflow,
        shouldShowConfirmationDialog: false,
      });
    },
    [openExampleWorkflowsDialog],
  );

  return (
    <>
      <ExperimentsNavigation title="Examples">
        <HeaderWithDescription
          title="Examples"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <CategoriesAndExampleWorkflowsWrapper>
          <CategoriesList selectedTag={tagQuery} onSelectTag={setTagQuery} />
          <ContainerWithIntersectionBar
            scrollableRef={scrollableContainerRef}
            noHeader
            content={
              <ExampleWorkflowsListWrapper>
                <ExampleWorkflowsList
                  scrollableContainerRef={scrollableContainerRef}
                  searchQuery={searchQuery}
                  tagQuery={tagQuery}
                  onExampleWorkflowDetailsClick={handleExampleWorkflowDetailsClick}
                  logCategory="examples_screen"
                />
              </ExampleWorkflowsListWrapper>
            }
            dense
          />
        </CategoriesAndExampleWorkflowsWrapper>
      </ExperimentsNavigation>
      {exampleWorkflowsDialog}
    </>
  );
}

const CategoriesAndExampleWorkflowsWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(180px, 0.75fr) 4fr',
  columnGap: theme.spacing(8),
  overflow: 'hidden',
}));

const ExampleWorkflowsListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}));
