import React, { ReactNode, useState } from 'react';

import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';

import stopPropagation from 'common/lib/stopPropagation';

const VERTICAL_OFFSET = 20;

type Props = {
  id: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  content: ReactNode | null;
  highlight: boolean;
};

export default function KeyPointPopper({
  id,
  open,
  anchorEl,
  content,
  highlight,
}: Props) {
  const [arrowEl, setArrowEl] = useState<HTMLElement | null>(null);

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="top"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, VERTICAL_OFFSET],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowEl,
          },
        },
      ]}
      style={{ zIndex: 2 }}
    >
      <PaperContent elevation={8} onPointerDown={stopPropagation} highlight={highlight}>
        {content}
      </PaperContent>
      <PopperArrow ref={setArrowEl} highlight={highlight} data-popper-arrow />
    </Popper>
  );
}

const PopperArrow = styled('div')<{ highlight: boolean }>(({ theme, highlight }) => ({
  '&:before': {
    display: highlight ? 'block' : 'none',
    content: '""',

    position: 'absolute',
    marginLeft: -VERTICAL_OFFSET / 2 + 1,

    borderWidth: VERTICAL_OFFSET / 2 - 1,
    borderStyle: 'solid',
    borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
  },
  '&:after': {
    content: '""',

    position: 'absolute',
    marginLeft: -VERTICAL_OFFSET / 2 + 3,

    borderWidth: VERTICAL_OFFSET / 2 - 3,
    borderStyle: 'solid',
    borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
  },
}));

const PaperContent = styled(Paper, {
  shouldForwardProp: prop => prop !== 'highlight',
})<{ highlight: boolean }>(({ theme, highlight }) => ({
  borderRadius: '5px',
  padding: theme.spacing(3),
  ...(highlight
    ? {
        color: theme.palette.primary.main,
        outline: `1px solid ${theme.palette.primary.main}`,
      }
    : null),
}));
