import React from 'react';

import { styled } from '@mui/material/styles';

import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import { ExperimentCard } from 'client/app/components/cards/ExperimentCard';
import { ExperimentsQuery } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';

const TutorialsListDataComponent = ({ data }: { data: ExperimentsQuery }) => {
  const experiments = data.experiments?.items || [];
  const isEnabledQuickStartActivation = useFeatureToggle('QUICK_START_ACTIVATION');

  return (
    <List>
      {experiments.map(experiment => (
        <ExperimentCard
          key={experiment.id}
          experiment={experiment}
          isLink
          variant="example"
          isEnabledQuickStartActivation={isEnabledQuickStartActivation}
        />
      ))}
    </List>
  );
};

const List = styled('div')(({ theme }) => ({
  ...experimentsStyles(theme).list,
}));

export default TutorialsListDataComponent;
