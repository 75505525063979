import React, { useMemo } from 'react';

import Download from '@mui/icons-material/Download';
import OpenInNew from '@mui/icons-material/OpenInNew';
import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import { paperClasses } from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import {
  accessibleDeviceFromGraphQL,
  deviceFromGraphQL,
} from 'client/app/api/deviceFromGraphql';
import { useDownloadRemoteFileFromPath } from 'client/app/api/filetree';
import CommentButton from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/CommentButton';
import DeviceSection from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/DeviceSection';
import Task from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/Task';
import {
  executionDetailsPlateSetupOpenedEvent,
  executionDetailsStagePreviewOpenedEvent,
} from 'client/app/apps/execution-details/ExecuteTab/metrics/executionDetailsMetrics';
import {
  getMetadataFileName,
  getMetadataFilesForStage,
} from 'client/app/apps/execution-details/helpers';
import { ExecutionStage, MetadataFile } from 'client/app/apps/execution-details/types';
import { ExecutionModeEnum, ExecutionStatusEnum } from 'client/app/gql';
import FileExtensionIcon from 'client/app/icons/FileExtensionIcon';
import { formatDateTime } from 'common/lib/format';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';

type Props = {
  executionStage: ExecutionStage;
  showTasks?: boolean;
  buttonSection?: React.ReactNode | React.ReactNode[];
};

export default function ExecutionStageCard({
  executionStage,
  buttonSection,
  showTasks = false,
}: Props) {
  const { mainDevice, peripheralDevices } = useMemo(
    () => ({
      mainDevice: deviceFromGraphQL(executionStage.deviceExecutionMode.mainDevice),
      peripheralDevices: executionStage.deviceExecutionMode.peripheralDevices.map(
        accessibleDeviceFromGraphQL,
      ),
    }),
    [
      executionStage.deviceExecutionMode.mainDevice,
      executionStage.deviceExecutionMode.peripheralDevices,
    ],
  );

  const { simulationId, orderNum } = executionStage.simulationStage;
  const previewLink = `/#/simulation-details/${simulationId}/preview?stage=${
    orderNum + 1
  }`;
  const setupLink = `/#/simulation-details/${simulationId}/setup?stage=${orderNum + 1}`;

  const showManualCopy =
    executionStage.deviceExecutionMode.mode === ExecutionModeEnum.Manual;
  const isCompleted =
    executionStage.status === ExecutionStatusEnum.EXECUTION_COMPLETED ||
    executionStage.status === ExecutionStatusEnum.EXECUTION_SUCCESS; // EXECUTION_SUCCESS is a subset of EXECUTION_COMPLETED where no tasks failed
  const completedDate = useMemo(
    () =>
      isCompleted
        ? formatDateTime(
            moment
              .max(executionStage.tasks.map(task => moment(task.lastModifiedAt)))
              .toDate(),
          )
        : null,
    [executionStage.tasks, isCompleted],
  );

  const { showConfigs, showLogs, configs, logs } = useFiles(executionStage);

  const stageNumber = executionStage.simulationStage.orderNum + 1;

  return (
    <Card>
      <CardContent showTasks={showTasks}>
        <StageIndexArea>
          <StageIndex variant="caption">Stage {stageNumber}</StageIndex>
        </StageIndexArea>
        <ExecutionModeArea>
          <DeviceSection device={mainDevice} showManualCopy={showManualCopy} />
          {peripheralDevices.map(device => (
            <DeviceSection key={device.id} device={device} />
          ))}
        </ExecutionModeArea>
        <ButtonsArea>{buttonSection}</ButtonsArea>
        <LinksArea>
          <NewTabLink
            href={previewLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => executionDetailsStagePreviewOpenedEvent(executionStage)}
          >
            <Typography variant="body1">Stage preview</Typography>
            <OpenInNew />
          </NewTabLink>
          <NewTabLink
            href={setupLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => executionDetailsPlateSetupOpenedEvent(executionStage)}
          >
            <Typography variant="body1">Plate setup</Typography>
            <OpenInNew />
          </NewTabLink>
        </LinksArea>
        {isCompleted && (
          <InfoArea>
            <CompleteDate variant="body2">
              Completed on <b>{completedDate}</b>
            </CompleteDate>
            <CommentButton
              comment={executionStage.comment}
              executionStageId={executionStage.id}
              orderNum={stageNumber}
            />
          </InfoArea>
        )}
        {showTasks && (
          <TasksArea>
            {showConfigs && (
              <Stack>
                <Typography variant="subtitle1" color="black">
                  Configuration
                </Typography>
                {configs.map(config => (
                  <MetadataFileRow key={config.name} file={config} />
                ))}
              </Stack>
            )}
            {showLogs && (
              <Stack>
                <Typography variant="subtitle1" color="black">
                  Logs
                </Typography>
                {logs.map(log => (
                  <MetadataFileRow key={log.name} file={log} />
                ))}
              </Stack>
            )}
            <>
              <Typography variant="subtitle1" color="black">
                Tasks
              </Typography>
              {executionStage.tasks.map(task => (
                <Task key={task.id} task={task} />
              ))}
            </>
          </TasksArea>
        )}
      </CardContent>
    </Card>
  );
}

const MetadataFileRow = ({ file }: { file: MetadataFile }) => {
  const downloadRemoteFileFromPath = useDownloadRemoteFileFromPath();
  const formattedName = getMetadataFileName(file);
  return (
    <FileRow key={file.name}>
      <FileExtensionIcon fileName={file.name} />
      <FileName variant="body1" title={formattedName}>
        {formattedName}
      </FileName>
      <Tooltip title="Download">
        <IconButton
          color="inherit"
          icon={<Download />}
          onClick={() => downloadRemoteFileFromPath(file.filetreeLink, formattedName)}
        />
      </Tooltip>
    </FileRow>
  );
};

function useFiles(executionStage: ExecutionStage) {
  return useMemo(() => {
    const files = getMetadataFilesForStage(executionStage);

    const logs: MetadataFile[] = [];
    const configs: MetadataFile[] = [];

    files.forEach(file => {
      switch (file.fileType) {
        case 'DEVICE_CONFIG': {
          configs.push(file);
          break;
        }
        case 'DEVICE_LOGS': {
          logs.push(file);
          break;
        }
        default:
          break;
      }
    });

    const showConfigs = configs.length > 0;
    const showLogs = logs.length > 0;

    return { showConfigs, showLogs, configs, logs };
  }, [executionStage]);
}

//#region Styles

const Card = styled(MuiCard)(({ theme }) => ({
  maxWidth: 'unset',
  border: `1px solid ${theme.palette.divider}`,
  margin: 'unset',
  [`&.${paperClasses.root}`]: {
    overflow: 'visible',
  },
}));

const CardContent = styled(MuiCardContent, {
  shouldForwardProp: prop => prop !== 'showTasks',
})<{ showTasks: boolean }>(({ theme, showTasks }) => ({
  display: 'grid',
  gridTemplateAreas: showTasks
    ? `
      "stage-index . "
      "execution-mode buttons"
      "tasks tasks"
      "links infos"
      `
    : `
      "stage-index . "
      "execution-mode buttons"
      "links infos"
      `,
  gridTemplateColumns: '1fr auto',
  gridTemplateRows: showTasks ? '14px auto auto 24px' : '14px auto 24px',
  columnGap: theme.spacing(5),
  rowGap: theme.spacing(4),

  padding: theme.spacing(6),
}));

const StageIndexArea = styled('div')({
  gridArea: 'stage-index',
});

const ExecutionModeArea = styled('div')(({ theme }) => ({
  gridArea: 'execution-mode',

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  overflowX: 'hidden',
}));

const ButtonsArea = styled(Stack)(({ theme }) => ({
  gridArea: 'buttons',

  justifyContent: 'flex-end',
  flexDirection: 'row',
  gap: theme.spacing(5),
}));

const LinksArea = styled('div')(({ theme }) => ({
  gridArea: 'links',

  display: 'flex',
  gap: theme.spacing(5),
}));

const InfoArea = styled('div')(({ theme }) => ({
  gridArea: 'infos',

  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(5),
}));

const TasksArea = styled('div')(({ theme }) => ({
  gridArea: 'tasks',

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),

  margin: theme.spacing(4, 0),
}));

const NewTabLink = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  textDecoration: 'none',
  color: 'black',

  '&:visited': {
    color: 'black',
  },
  '& svg': {
    fontSize: '14px',
  },
}));

const CompleteDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const StageIndex = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  fontWeight: 600,
}));

const FileRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

const FileName = styled(Typography)({
  flexGrow: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

//#endregion
