import React, { useCallback, useMemo } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';

import { indexBy } from 'common/lib/data';
import Checkbox from 'common/ui/components/Checkbox';
import FilterChip, {
  FilterChipWithPopoverProps,
} from 'common/ui/components/FilterChip/FilterChip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type Option<T> = {
  label: string;
  value: T;
  selected: boolean;
};

type Props<T> = FilterChipWithPopoverProps<Option<T>[]>;

/**
 * A FilterChip that has multiple checkboxes to use as filter.
 */
export default function FilterChipWithCheckbox<T>(props: Props<T>) {
  const classes = useStyles();
  const { filterValue, defaultChipLabel, onFilter, size } = props;
  const labelOptionMap = useMemo(() => indexBy(filterValue, 'label'), [filterValue]);

  // Update the label of the chip depending on whether there is 0, 1, 2+ items.
  let valueLabel = defaultChipLabel;
  const selectedValues = filterValue.filter(status => {
    return status.selected ? status.value : undefined;
  });
  if (selectedValues.length === 1) {
    valueLabel = selectedValues[0].label;
  } else if (selectedValues.length > 0) {
    valueLabel = `${defaultChipLabel} - ${selectedValues.length} selected`;
  }

  const chipLabelVariant = selectedValues.length > 0 ? 'filled' : 'outlined';

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedOption = labelOptionMap[e.target.id];
      const selectedValue = selectedOption.value;
      const newValue = filterValue.map(option => {
        if (selectedValue === option.value) {
          return {
            ...option,
            selected: e.target.checked,
          };
        }
        return option;
      });
      onFilter(newValue);
    },
    [filterValue, labelOptionMap, onFilter],
  );

  const clearSelection = useCallback(() => {
    const newValue = filterValue.map(option => ({
      ...option,
      selected: false,
    }));
    onFilter(newValue);
  }, [filterValue, onFilter]);

  return (
    <FilterChip
      heading={props.heading}
      chipLabel={valueLabel}
      chipLabelVariant={chipLabelVariant}
      filterValue={filterValue}
      onDelete={clearSelection}
      isActive={selectedValues.length > 0}
      className={props.className}
      size={size}
      popoverContent={
        <div className={classes.content}>
          {filterValue.map(option => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    id={option.label}
                    checked={option.selected}
                  />
                }
                label={option.label}
                key={option.label}
              />
            );
          })}
        </div>
      }
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '276px',
    marginTop: theme.spacing(3),
  },
}));
