import React, { CSSProperties } from 'react';

import { FallbackRender } from '@sentry/react';

import Colors from 'common/ui/Colors';
import { isLocalOrNinjaEnvironment } from 'common/ui/lib/envs';

// Fallback UI render function for if a child component crashes.
// This is passed into the Sentry.io error boundary component.
const errorFallback: FallbackRender = ({ error }) => {
  let boxContents;
  if (isLocalOrNinjaEnvironment()) {
    boxContents = (
      <>
        <p>We&apos;re very sorry.</p>
        <p>
          The panel below displays some text that explains what happened. Please copy the
          text and report it.
        </p>
        <p>In the meantime, please reload the page.</p>
        <textarea
          rows={20}
          cols={100}
          style={styles.textarea}
          defaultValue={error.stack}
          disabled
        />
      </>
    );
  } else {
    boxContents = <p>We&apos;re very sorry. Please reload the page.</p>;
  }
  return (
    <div style={styles.screen}>
      <div style={styles.errorBox}>
        <div style={styles.topStripe}>
          <h1 style={styles.header}>&#x1F6A7; Something went wrong...</h1>
        </div>
        <div style={styles.boxContents}>{boxContents}</div>
      </div>
    </div>
  );
};

// Not using JSS here, want to keep this component as simple as possible.
// This component is the last-resort error handling and should never crash
// or have any issues with rendering. Let's keep it as bare-bones as possible.
const styles = {
  screen: {
    alignItems: 'center',
    background: `linear-gradient(135deg, ${Colors.BLUE} 0%, ${Colors.GREEN} 100%)`,
    color: 'black',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: '16px',
  } as CSSProperties,
  errorBox: {
    backgroundColor: 'white',
    border: `3px solid ${Colors.WARNING}`,
  },
  topStripe: {
    backgroundColor: Colors.DARK_YELLOW,
  },
  boxContents: {
    padding: '16px',
  },
  header: {
    fontSize: '20px',
    margin: 0,
    padding: '16px',
  },
  textarea: {
    border: `1px solid ${Colors.GREY_20}`,
    fontSize: '12px',
    marginTop: '16px',
    // The layout in centered on the screen. The user resizing the textarea
    // makes the whole layout move around.
    resize: 'none',
  } as CSSProperties,
};

export default errorFallback;
