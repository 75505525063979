import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import uniqBy from 'lodash/uniqBy';

import {
  deviceFromGraphQL,
  getDeviceModelLabel,
  GraphQLDevice,
} from 'client/app/api/deviceFromGraphql';
import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import { DeviceDisabledReason } from 'client/app/apps/workflow-builder/panels/workflow-settings/devices/DeviceSelectorPanel';
import { useFilteredDevices } from 'client/app/components/DeviceLibrary/filterDevices';
import { getDeviceContentSourceFilterOptions } from 'client/app/components/DeviceLibrary/types';
import {
  useDeviceFilterBar,
  UseDeviceFilterBarType,
} from 'client/app/components/DeviceLibrary/useDeviceFilterBar';
import { DeviceCommonFragment } from 'client/app/gql';
import {
  DATA_ONLY_DUMMY_DEVICE,
  MANUAL_DEVICE_DESCRIPTION,
} from 'common/constants/manual-device';
import { pluralize } from 'common/lib/format';
import {
  GenericDeviceType,
  getGenericDeviceTypeFromAnthaClass,
} from 'common/types/bundleConfigUtils';
import { ContentSourceType } from 'common/types/contentSource';
import { Device } from 'common/types/device';
import { loadingWithinDialog } from 'common/ui/commonStyles';
import Button from 'common/ui/components/Button';
import CardGrid from 'common/ui/components/CardGrid';
import { DeviceConfigSelection } from 'common/ui/components/ConfigSelector';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import DeviceCard, { PrefixCard } from 'common/ui/components/DeviceCard/DeviceCard';
import DialogWrap from 'common/ui/components/Dialog/DialogWrap';
import FilterChipWithAutocomplete from 'common/ui/components/FilterChip/FilterChipWithAutocomplete';
import FilterChipWithCheckbox from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import { Option } from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import LinearProgress from 'common/ui/components/LinearProgress';
import SearchField from 'common/ui/components/SearchField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

type ClearSelectionProps = {
  selectedDeviceCount: number;
  totalDeviceCount: number;
  onClear: () => void;
};

type Props = {
  isLoading: boolean;
  /**
   * devices list (non-filtered)
   */
  devices: readonly GraphQLDevice[];
  /**
   * Component that will render the actions in each individual DeviceCard
   */
  renderActions?: (device: Device) => ReactNode;
  onSelect?: (id: string) => void;
  selectedDeviceIds: string[];
  showSelectionStatus?: boolean;
  /**
   * Show/hide Data-only and Manual device cards.
   * Used for execution mode selection in workflow builder settings panel.
   */
  showManualDeviceRelatedCards?: boolean;
  smallCard?: boolean;
  selectedConfigs?: DeviceConfigSelection;

  /**
   * In some case the parent needs to know about devices filters.
   * For instance to select only filtered devices.
   *
   * If we have those filters we will override the local one.
   */
  parentDeviceFilters?: UseDeviceFilterBarType;
  dialog?: boolean;

  /**
   * If this function returns true for a device, that device will be greyed out.
   */
  isDeviceDisabled?: (device: Device | DeviceCommonFragment) => DeviceDisabledReason;

  /**
   * When specified, additional cards with these properties will be prefixed to the list of devices
   * when displayed and will be available for selection in all the same ways.
   */
  prefixItems?: {
    // The identifier to be returned as the selection if this card is selected.  A value that could
    // not possibly be a deviceID should be used.
    id: string;
    // The title text of the card
    title: string;
    // The body text of the card
    body: string;
    // An element containing the image for the card
    image: JSX.Element;
  }[];
  /**
   * Show information related to the number of devices selected, and show a Clear All button to reset
   * the device selection. This requires the selected device state to be handled outside of the DeviceLibrary.
   * This is currently only used in the Deck Options Panel.
   */
  clearSelectionProps?: ClearSelectionProps;
};

/**
 * Renders a Device Library and device cards.
 * A parent is required to pass down the devices.
 *
 */
export default function DeviceLibrary(props: PropsWithChildren<Props>) {
  const classes = useStyles();

  const {
    dialog,
    devices,
    isLoading,
    renderActions,
    selectedDeviceIds,
    onSelect,
    showSelectionStatus,
    showManualDeviceRelatedCards,
    smallCard,
    selectedConfigs,
    parentDeviceFilters,
    prefixItems,
    isDeviceDisabled,
    clearSelectionProps,
  } = props;
  const manualDevice = useMemo(() => {
    const manualDeviceCommon = devices.find(d => d.model.name === 'Manual');
    return manualDeviceCommon ? deviceFromGraphQL(manualDeviceCommon) : null;
  }, [devices]);

  const deviceFilterBar = useDeviceFilterBar();

  /* We get states and state's setters for the DeviceFilterBar.
   * In some cases the parent takes care of passing them, if that is the case we override.
   * Parents are always right.
   */
  const deviceFilters = useMemo(
    () => ({ ...deviceFilterBar, ...parentDeviceFilters }),
    [deviceFilterBar, parentDeviceFilters],
  );
  const { filterQuery, filterModel, filterSource, filterType } = deviceFilters;
  const noFiltersApplied =
    filterQuery === '' && filterModel === '' && filterSource === '' && filterType === '';
  const filtersApplied = !noFiltersApplied;

  const filteredArrayOfDevices = useFilteredDevices(devices, {
    filterQuery,
    filterModel,
    filterSource,
    filterType,
  });

  const [contentSourceTypes, setContentSourceTypes] = useStateWithURLParams({
    paramName: 'type',
    paramType: 'string[]',
    defaultValue: [],
  });

  const [types, setTypes] = useState<Option<GenericDeviceType>[]>(() => {
    const presentTypes = new Set(
      devices.map(d => getGenericDeviceTypeFromAnthaClass(d.model.anthaLangDeviceClass)),
    );

    const types: Option<GenericDeviceType>[] = [
      {
        label: 'Liquid Handler',
        selected: false,
        value: 'LiquidHandler',
      },
      {
        label: 'Dispenser',
        selected: false,
        value: 'Dispenser',
      },
      {
        label: 'Plate Reader',
        selected: false,
        value: 'PlateReader',
      },
      {
        label: 'Plate Washer',
        selected: false,
        value: 'PlateWasher',
      },
      {
        label: 'Shaker / Incubator',
        selected: false,
        value: 'ShakerIncubator',
      },
    ];

    return types.filter(type => presentTypes.has(type.value));
  });

  const isDataOnlySelected = selectedDeviceIds.includes(DATA_ONLY_DUMMY_DEVICE.id);
  const isManualSelected = !!manualDevice && selectedDeviceIds.includes(manualDevice.id);
  const areDevices = devices.length - Number(!!manualDevice) > 0;

  const onChangeSearchQuery = useCallback(
    (query: string) => {
      deviceFilters.onChangeFilterQuery(query);
    },
    [deviceFilters],
  );

  const deviceOptions = useMemo(
    () =>
      uniqBy(
        filteredArrayOfDevices.map(device => ({
          label: getDeviceModelLabel(device),
          value: device.model.id,
        })),
        'value',
      ).sort((a, b) => a.label.localeCompare(b.label)),
    [filteredArrayOfDevices],
  );
  const onFilterDeviceModel = useCallback(
    async (model?: string) => {
      const filteredModel = model ?? '';
      return deviceFilters.onChangeFilterModel(filteredModel);
    },
    [deviceFilters],
  );

  const onFilterContentSource = useCallback(
    (newValue: Option<ContentSourceType>[]) => {
      const newSources = newValue
        .filter(option => option.selected)
        .map(option => option.label);
      setContentSourceTypes(newSources);
      return deviceFilters.onChangeFilterSource(newSources);
    },
    [deviceFilters, setContentSourceTypes],
  );

  const dataOnlyDisabled = isDeviceDisabled?.(DATA_ONLY_DUMMY_DEVICE);
  const manualDisabled = manualDevice && isDeviceDisabled?.(manualDevice);

  const onFilterType = useCallback(
    (newValue: Option<GenericDeviceType>[]) => {
      const newTypes = newValue
        .filter(option => option.selected)
        .map(option => option.value);
      setTypes(newValue);
      return deviceFilters.onChangeFilterType(newTypes);
    },
    [deviceFilters],
  );

  return isLoading ? (
    <DialogWrap dialog={!!dialog} className={classes.linearProgress}>
      <LinearProgress />
    </DialogWrap>
  ) : (
    <ContainerWithIntersectionBar
      headerLeftContent={
        <>
          <div className={classes.filter}>
            <FilterChipWithAutocomplete
              heading="Filter by Model"
              defaultChipLabel="Model"
              dropdownOptions={deviceOptions}
              filterValue={filterModel ?? ''}
              onFilter={onFilterDeviceModel}
              className={classes.chip}
            />
            <FilterChipWithCheckbox
              heading="Filter by Owner"
              defaultChipLabel="Device Owner"
              filterValue={getDeviceContentSourceFilterOptions(contentSourceTypes ?? [])}
              onFilter={onFilterContentSource}
              className={classes.chip}
            />
            <FilterChipWithCheckbox
              heading="Filter by Type"
              defaultChipLabel="Type"
              filterValue={types}
              onFilter={onFilterType}
              className={classes.chip}
            />
          </div>
          {clearSelectionProps && (
            <ClearDeviceSelectionActions {...clearSelectionProps} />
          )}
        </>
      }
      headerRightContent={
        <SearchField
          placeholder="Search"
          onQueryChange={onChangeSearchQuery}
          className={classes.searchField}
        />
      }
      content={
        <CardGrid>
          {!areDevices && (
            <NoEntitiesMessage
              entityName="devices"
              messageType={MessageType.EMPTY_TAB}
              secondaryText="Register a device on Synthace Hub."
            />
          )}
          {filtersApplied && filteredArrayOfDevices.length === 0 && (
            <NoEntitiesMessage
              entityName="devices"
              messageType={MessageType.NO_FILTER_RESULTS}
              searchQuery={filterQuery}
            />
          )}
          {noFiltersApplied &&
            (prefixItems ?? []).map(item => (
              <PrefixCard
                key={item.id}
                id={item.id}
                title={item.title}
                image={item.image}
                body={item.body}
                selected={selectedDeviceIds.includes(item.id)}
                onSelect={onSelect}
                showSelectionStatus={showSelectionStatus}
                small={smallCard}
              />
            ))}
          {showManualDeviceRelatedCards && noFiltersApplied && manualDevice && (
            <>
              <DeviceCard
                device={DATA_ONLY_DUMMY_DEVICE}
                key={DATA_ONLY_DUMMY_DEVICE.id}
                onSelect={onSelect}
                selected={isDataOnlySelected}
                disabled={dataOnlyDisabled?.disabled}
                disabledWithReason={dataOnlyDisabled?.reason}
                disabledButtonCopy={dataOnlyDisabled?.disabledButtonCopy}
                showSelectionStatus={showSelectionStatus}
                small={smallCard}
                description="No device needed"
              />
              <DeviceCard
                device={manualDevice}
                key={manualDevice.id}
                onSelect={onSelect}
                selected={isManualSelected}
                disabled={manualDisabled?.disabled}
                disabledButtonCopy={manualDisabled?.disabledButtonCopy}
                disabledWithReason={manualDisabled?.reason}
                showSelectionStatus={showSelectionStatus}
                small={smallCard}
                description={MANUAL_DEVICE_DESCRIPTION}
              />
            </>
          )}
          {filteredArrayOfDevices.map(deviceCommon => {
            const device = deviceFromGraphQL(deviceCommon);
            const disabledWithReason = isDeviceDisabled?.(deviceCommon);
            return (
              <DeviceCard
                device={device}
                key={device.id}
                renderActions={renderActions}
                selected={selectedDeviceIds.includes(device.id)}
                disabled={checkDeviceCardDisabled(
                  selectedConfigs,
                  device,
                  disabledWithReason,
                )}
                disabledWithReason={disabledWithReason?.reason}
                disabledButtonCopy={disabledWithReason?.disabledButtonCopy}
                onSelect={onSelect}
                showSelectionStatus={showSelectionStatus}
                small={smallCard}
                contentSource={device.contentSource}
              />
            );
          })}
        </CardGrid>
      }
    />
  );
}

DeviceLibrary.defaultProps = {
  selectedDeviceIds: [],
};

const ClearDeviceSelectionActions = React.memo(function (props: ClearSelectionProps) {
  const classes = useStyles();
  const { selectedDeviceCount, totalDeviceCount, onClear } = props;
  const shouldShowClearButton = selectedDeviceCount > 0;

  return (
    <div className={classes.clearActions}>
      <Divider className={classes.divider} orientation="vertical" />
      <Typography variant="h5" color="textPrimary">
        {`${selectedDeviceCount} out of ${pluralize(
          totalDeviceCount,
          'execution mode',
        )} selected`}
      </Typography>
      {shouldShowClearButton && (
        <Button
          className={classes.clearButton}
          onClick={onClear}
          variant="secondary"
          size="small"
        >
          Clear all
        </Button>
      )}
    </div>
  );
});

function checkDeviceCardDisabled(
  selectedConfigs: DeviceConfigSelection | undefined,
  device: Device,
  disabledWithReason: { disabled: boolean; reason?: string | undefined } | undefined,
): boolean | undefined {
  if (disabledWithReason?.disabled) return true;

  const deviceHasntBeenSelected = selectedConfigs && !selectedConfigs[device.id];
  const deviceRunConfigCount = device.runConfigs?.length ?? 0;
  return deviceHasntBeenSelected && deviceRunConfigCount > 1;
}

const useStyles = makeStylesHook(theme => ({
  ...experimentsStyles(theme),
  linearProgress: { ...loadingWithinDialog.linearProgress, height: '100%' },
  clearActions: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(5),
    height: '32px',
    margin: theme.spacing(6, 0, 5, 5),
    marginRight: 'auto',
  },
  divider: {
    height: '16px',
  },
  searchField: {
    margin: theme.spacing(6, 0, 5, 5),
  },
  clearButton: {
    minWidth: 'fit-content',
    whiteSpace: 'nowrap',
  },
}));
