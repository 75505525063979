import React, {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

export { ManualInterventionHighlightProvider as default, useManualInterventionHighlight };

const ManualInterventionHighlightContext = createContext<{
  highlight: boolean;
  toggle: () => void;
}>({
  highlight: false,
  toggle: () => {
    throw new Error('Not implemented');
  },
});

const useManualInterventionHighlight = () =>
  useContext(ManualInterventionHighlightContext);

type Props = {
  children: ReactNode | ReactNode[];
};

function ManualInterventionHighlightProvider({ children }: Props) {
  const [highlight, setHighlight] = useState(false);
  const toggle = useCallback(() => {
    setHighlight(flag => !flag);
  }, []);
  return (
    <ManualInterventionHighlightContext.Provider
      value={{
        highlight,
        toggle,
      }}
    >
      {children}
    </ManualInterventionHighlightContext.Provider>
  );
}
