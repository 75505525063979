import React from 'react';

import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';
import RecentSection from 'client/app/apps/landing-page/RecentSection';
import TutorialsSection from 'client/app/apps/landing-page/TutorialsSection';
import { useCreateNew } from 'client/app/components/NavigationSidepanel';
import { experimentsRoutes } from 'client/app/lib/nav/actions';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';

export default function LandingPageSceen() {
  const { createNew, templateWorklowsDialog } = useCreateNew();

  return (
    <ExperimentsNavigation title="Home">
      <Container>
        <Section>
          <SectionTitle>Start</SectionTitle>
          <GettingStarted>
            <DesignANewWorkflowButton onClick={() => createNew('new-workflow-builder')}>
              <ButtonTitle>Design a new workflow</ButtonTitle>
              <ButtonDescription>
                Create a new workflow or adapt an existing one to your needs
              </ButtonDescription>
            </DesignANewWorkflowButton>
            <BrowseTemplatesButton
              onClick={() => createNew('new-workflow-from-template')}
            >
              <ButtonTitle>Browse templates</ButtonTitle>
              <ButtonDescription>
                Customize and run a pre-defined template workflow
              </ButtonDescription>
            </BrowseTemplatesButton>
            <NewExperimentButton onClick={() => createNew('new-experiment')}>
              <ButtonTitle>New experiment</ButtonTitle>
              <ButtonDescription>
                Organize and visualize the entire experimental journey
              </ButtonDescription>
            </NewExperimentButton>
          </GettingStarted>
        </Section>
        <Section>
          <SectionTitle>Recent</SectionTitle>
          <RecentSection />
        </Section>
        <section>
          <SectionTitle>Learn</SectionTitle>
          <TutorialsSection />
          <LearnMore>
            <Link to={experimentsRoutes.tutorials.getPath()}>
              <Button variant="secondary" color="primary">
                View more tutorials...
              </Button>
            </Link>
          </LearnMore>
        </section>
      </Container>
      {templateWorklowsDialog}
    </ExperimentsNavigation>
  );
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(9),
  overflowY: 'auto',
}));

const GettingStarted = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplate: `
    'w ne' auto
    'w se' auto / 2fr 1fr`,
  gap: spacing(6),
}));

const ButtonTitle = styled('span')({
  fontFamily: 'Inter',
  fontSize: 14,
  fontWeight: 900,
});

const ButtonDescription = styled('span')({
  fontFamily: 'Inter',
  fontSize: 13,
  fontWeight: 300,
});

const StartButton = styled('button')(({ theme: { spacing } }) => ({
  border: '0',
  borderRadius: spacing(3),
  display: 'grid',
  gridTemplate: `
    'action' auto
    'explanation' auto / auto
  `,
  justifyContent: 'start',
  alignContent: 'end',
  justifyItems: 'start',
  alignItems: 'start',
  backgroundPosition: 'top left',
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  color: Colors.TEXT_PRIMARY,
  padding: spacing(6),
  gap: spacing(3),
  textAlign: 'left',
  cursor: 'pointer',
  outline: `1px solid rgba(0,0,0,.2)`,
  outlineOffset: -1,

  '&:hover,&:focus': {
    color: Colors.PRIMARY_MAIN,
    outlineColor: Colors.PRIMARY_MAIN,
  },
}));

const DesignANewWorkflowButton = styled(StartButton)(({ theme: { spacing } }) => ({
  gridArea: 'w',
  gap: spacing(4),
  backgroundImage: 'url(/app/assets/images/landing/design-a-new-workflow-bg.png)',

  [`${ButtonTitle}`]: {
    fontSize: 22,
  },

  [`${ButtonDescription}`]: {
    fontSize: 15,
    maxWidth: 230,
  },
}));

const BrowseTemplatesButton = styled(StartButton)({
  backgroundImage: 'url(/app/assets/images/landing/browse-templates-bg.png)',
  gridArea: 'ne',

  [`${ButtonDescription}`]: {
    maxWidth: 200,
  },
});

const NewExperimentButton = styled(StartButton)({
  backgroundImage: 'url(/app/assets/images/landing/new-experiment-bg.png)',
  gridArea: 'se',

  [`${ButtonDescription}`]: {
    maxWidth: 200,
  },
});

const SectionTitle = styled('h1')(({ theme }) => ({
  margin: `0 0 ${theme.spacing(7)} 0`,
  fontSize: 32,
  fontWeight: 900,
  fontFamily: 'Inter',
  lineHeight: '32px',
  '::after': {
    content: '"."',
    color: Colors.PRIMARY_MAIN,
  },
}));

const Section = styled('section')(({ theme }) => ({
  width: theme.breakpoints.values.md,
}));

const LearnMore = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  display: 'grid',
  placeItems: 'center',
}));
