import BackHand from '@mui/icons-material/BackHand';
import InfoRounded from '@mui/icons-material/InfoRounded';
import { styled } from '@mui/material/styles';

import Colors from 'common/ui/Colors';
import {
  KEY_POINT_WIDTH,
  SLIDER_BAR_HEIGHT,
  SLIDER_CURSOR_HEIGHT,
  SLIDER_CURSOR_WIDTH,
} from 'common/ui/components/simulation-details/StepSlider/helpers';

export const zIndex = {
  progress: 1,
  keyPoint: 2,
  breakpoint: 2,
  cursor: 3,
};

const SliderContainer = styled('main')({
  position: 'relative',
});

const Slider = styled('div')<{ dragging: boolean }>(({ dragging }) => ({
  height: SLIDER_BAR_HEIGHT,
  backgroundColor: Colors.GREY_10,
  cursor: dragging ? 'grabbing' : 'initial',
}));

const SliderProgress = styled('div')({
  position: 'absolute',

  top: 0,
  left: 0,
  height: SLIDER_BAR_HEIGHT,

  backgroundColor: Colors.SLIDER_BAR,
  zIndex: zIndex.progress,
});

const KeyPointItem = styled('span')(({ theme }) => ({
  position: 'absolute',

  top: 0,
  bottom: 0,
  width: KEY_POINT_WIDTH,

  backgroundColor: Colors.KEY_POINT_DEFAULT,
  zIndex: zIndex.keyPoint,
  borderRadius: theme.spacing(2),

  cursor: 'pointer',
}));

const SliderCursor = styled('div')<{
  highlightColor?: string;
}>(({ highlightColor, theme }) => ({
  position: 'absolute',

  top: -4,
  width: SLIDER_CURSOR_WIDTH,
  height: SLIDER_CURSOR_HEIGHT,
  zIndex: zIndex.cursor,

  background: Colors.SLIDER_BUTTON,
  borderRadius: theme.spacing(2),

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  outline: highlightColor ? `2px solid ${highlightColor}` : 'none',
  cursor: 'grab',
}));

const SliderCursorInner = styled('div')({
  width: '4px',
  height: '30%',
  background: 'transparent',
  borderLeft: `1px solid ${Colors.WHITE}`,
  borderRight: `1px solid ${Colors.WHITE}`,
});

const ParallelTransferStageBreakpoint = styled('div')<{ position: number }>(
  ({ theme, position }) => ({
    position: 'absolute',

    top: -4,
    left: position,
    width: SLIDER_CURSOR_WIDTH,
    height: SLIDER_CURSOR_HEIGHT,

    zIndex: zIndex.breakpoint,
    backgroundColor: theme.palette.divider,
    borderRadius: theme.spacing(2),
  }),
);

const ManualInterventionStandaloneIcon = styled(BackHand)({
  transform: `rotate(30deg)`,
  width: 16,
  color: Colors.MANUAL_INTERVENTION,
});
const ManualInterventionIcon = styled(ManualInterventionStandaloneIcon)({
  transform: `rotate(30deg) translate(4px, 4px)`,
});

const PromptInfoStandaloneIcon = styled(InfoRounded)({
  width: 16,
  color: Colors.KEY_POINT_DEFAULT,
});
const PromptInfoIcon = styled(PromptInfoStandaloneIcon)({
  transform: 'translate(0, 6px)',
});

export {
  KeyPointItem,
  ParallelTransferStageBreakpoint,
  Slider,
  SliderContainer,
  SliderCursor,
  SliderCursorInner,
  SliderProgress,
  ManualInterventionIcon,
  ManualInterventionStandaloneIcon,
  PromptInfoIcon,
  PromptInfoStandaloneIcon,
};
