import { ContentSourceType } from 'common/types/contentSource';
import { getHumanReadableSource } from 'common/ui/components/DeviceCard/DeviceCard';
import { Option } from 'common/ui/components/FilterChip/FilterChipWithCheckbox';

export function getDeviceContentSourceFilterOptions(
  contentSourceTypes: Option<ContentSourceType>['label'][] = [],
): Option<ContentSourceType>[] {
  return Object.values(ContentSourceType).map(source => {
    const label = getHumanReadableSource(source).humanReadableName;
    return { value: source, label, selected: contentSourceTypes.includes(label) };
  });
}
