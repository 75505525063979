import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useEarlyTokenContext } from 'client/app/apps/authentication/hooks';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { isEnabled } from 'common/features/featureTogglesForUI';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import getGATrackingEnvironment from 'common/ui/getGATrackingEnvironment';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

function useGetAccessToken() {
  const { earlyGetAccessTokenSilently } = useEarlyTokenContext();
  return earlyGetAccessTokenSilently;
}

export type VisserverIFrameProps = {
  /**
   * Name of the visserver view to load. e.g. specifying "doe" will load the URL
   * "/vis/doe" in the iframe.
   */
  view: string;
  /**
   * Params to append to url
   */
  params?: Record<string, string> | URLSearchParams;
  onReady?: () => void;
  shouldNotResize?: boolean;
  onReload?: () => void;
};

/**
 * Shows a visserver app within an iframe.
 *
 * TODO: The content-security-policy of antha should restrict iframe content to
 * only pages within the current domain (T3432). For example:
 *
 *   <meta http-equiv="Content-Security-Policy" content="child-src 'self'">
 */
export default function VisserverIFrame({
  view,
  params,
  onReady,
  shouldNotResize,
  onReload,
}: VisserverIFrameProps) {
  const classes = useStyles();
  const [height, setHeight] = useState<number>();

  const isVisserver3Enabled = useFeatureToggle('VISSERVER_BOKEH_3');
  const visserverRootUrl = isVisserver3Enabled ? '/vis3' : '/vis';
  const iframeURL = useMemo<string>(() => {
    const urlParams =
      params instanceof URLSearchParams ? params : new URLSearchParams(params);
    return `${visserverRootUrl}/view/${view}?${urlParams}`;
  }, [params, view, visserverRootUrl]);

  const getAccessToken = useGetAccessToken();
  const currentUserId = useUserProfile()?.id;

  // to set the iframe source we need to set a cookie with the authentication token so the request can be anthenticated on visserver side
  // therefore we need to first set the cookie and only then change the iframe source
  // we cannot directly use the src attribute in the returned iframe below
  const setIframeSource = useCallback(async () => {
    const token = await getAccessToken();
    // use max-age=10 to make the cookie valid 10s, which should be plenty enough to start visserver
    // this cookie will be read by visserver to get the auth token, so it can be used in the initial requests made to appserver
    // we have to rely on cookies as bokeh can create a session from the initial request or later in a websocket. That websocket needing some auth somewhere.
    document.cookie = `visserver.authorization=${token};secure=true;samesite=lax;max-age=10`;

    // a cookie to pass local timezone to the Bokeh app for rendering timestamps
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    document.cookie = `visserver.timezone=${timezone};max-age=10`;

    // We store the tracking env and for the purposes of allowing visserver iframe
    // to track to correct environment with product analytics tools
    const trackingEnv = getGATrackingEnvironment(window.location);
    document.cookie = `visserver.trackingEnv=${trackingEnv};max-age=10`;

    // Store the userID so that it can be passed to Sentry to link visserver errors to users
    // Does not have an expiration, as it needs to be sent as a cookie with the error
    document.cookie = `visserver.userid=${currentUserId};secure=true`;

    const iframe = document.getElementById('visserver-iframe');
    if (!iframe) {
      throw new Error('iframe missing');
    }
    if (!(iframe instanceof HTMLIFrameElement)) {
      throw new Error('iframe is not an iframe');
    }
    iframe.src = iframeURL;
  }, [currentUserId, getAccessToken, iframeURL]);

  useEffect(() => {
    const handleContentWindowMessage = (ev: MessageEvent) => {
      if (
        ev.source === null ||
        ev.source instanceof MessagePort ||
        ev.source instanceof ServiceWorker ||
        location.origin !== ev.origin
      ) {
        console.warn('unexpected source or origin for message', ev);
        return;
      }

      const name = ev.data?.name;
      switch (name) {
        // Visserver will send an 'iframeResize' message each time the content changes
        // size. Listen for that message and update the iframe. Internally this uses
        // ResizeObserver.
        case 'iframeResize': {
          if (!shouldNotResize) {
            setHeight(ev.data.height);
          }
          break;
        }
        // Respond to a query for feature state based on toggles.
        case 'feature': {
          const feature = ev.data.feature;
          const enabled = isEnabled(feature);
          ev.source.postMessage({ name, feature, enabled }, ev.origin);
          break;
        }
        case 'visserverReady': {
          onReady?.();
          break;
        }
        case 'visserverReload': {
          onReload?.();
          void setIframeSource();
          break;
        }
        case 'token': {
          // get an auth token and send it back to the caller
          const { source, origin } = ev;
          (async () => {
            const token = await getAccessToken();
            source.postMessage({ token }, origin);
          })();
          break;
        }
        default: {
          break;
        }
      }
    };
    window.addEventListener('message', handleContentWindowMessage);
    return () => window.removeEventListener('message', handleContentWindowMessage);
  }, [getAccessToken, onReady, onReload, setIframeSource, shouldNotResize]);

  useEffect(() => {
    void setIframeSource();
  }, [setIframeSource]);

  return (
    <iframe
      key={view}
      id="visserver-iframe"
      className={classes.iframe}
      style={{ height }}
    />
  );
}

const useStyles = makeStylesHook({
  iframe: {
    border: 'none',
    width: '100%',
    height: '100%',
  },
});
