import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import {
  DEFAULT_CHERRY_PICK_WORKFLOW_NAME,
  LiquidTransfer,
  PlateMinSizeByName,
  TransfersInfoByPlate,
  TransfersTree,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import { SuccessfullySavedWorkflow } from 'client/app/lib/workflow/SuccessfullySavedWorkflow';
import {
  ConfiguredDevice,
  defaultWorkflowConfig,
  WorkflowConfig,
} from 'common/types/bundle';
import { PlateType } from 'common/types/plateType';

export type SetCherryPick = Dispatch<SetStateAction<LiquidTransfer[]>>;
export type PlatesByName = { [plateName: string]: PlateType };
export type SetPlatesByName = Dispatch<SetStateAction<PlatesByName>>;
export type SetBundleConfig = Dispatch<SetStateAction<WorkflowConfig>>;
export type AllPlatesByType = { [plateType: string]: PlateType };
export type SetFileName = Dispatch<SetStateAction<string | null>>;
type SetPlateMinSizeByName = Dispatch<SetStateAction<PlateMinSizeByName>>;
type SetTransferInfoByPlate = Dispatch<SetStateAction<TransfersInfoByPlate>>;
type SetTransfersTree = Dispatch<SetStateAction<TransfersTree>>;
type ActiveStep = { source: number; destination: number };
export type SetActiveStep = Dispatch<SetStateAction<ActiveStep>>;
type SetEnforceOrder = Dispatch<SetStateAction<boolean>>;
type SetSelectedDevice = Dispatch<SetStateAction<ConfiguredDevice[]>>;
type SetLastSavedWorkflow = Dispatch<
  SetStateAction<SuccessfullySavedWorkflow | null>
>;
export type SetWorkflowName = Dispatch<SetStateAction<string>>;
type SetIsSavingWorkflow = Dispatch<SetStateAction<boolean>>;

export type CherryPickContext = {
  cherryPick: LiquidTransfer[];
  setCherryPick: SetCherryPick;
  // Users need to specify a policy for each liquid. Having this list
  // allows us to render a nice table/dialog with all the liquids in the
  // CherryPick, so users won't have to scroll through the entire CherryPick
  uniqueLiquidNames: string[];
  uniqueDestinationPlateNames: string[];
  // A set of both source and destination plates
  uniquePlateNames: string[];
  // A map e.g, { MyPlate1: Plate{} }, so we can easily look up
  // which Antha plate corresponds to user defined plate names.
  // It will be used when building the json to feed to the cherry pick element
  platesByName: PlatesByName;
  setPlatesByName: SetPlatesByName;
  // Bundle specific config, e.g. device, tipTipes, ignorePhysicalSimulation
  bundleConfig: WorkflowConfig;
  setBundleConfig: SetBundleConfig;
  // We need plateTypes for two reasons: to visualize the plate, and to
  // add the `well_volume_residual_ul` to the SourceVolume
  allPlatesByType: AllPlatesByType;
  uploadedFileName: string | null;
  setUploadedFileName: SetFileName;
  // Store the minimum size a plate must have, based on the users' defined transfers
  plateMinSizeByName: PlateMinSizeByName;
  setPlateMinSizeByName: SetPlateMinSizeByName;
  // This object stores all the information regarding the liquid contents of
  // the plates. It’s used to colour the wells.
  transfersInfoByPlate: TransfersInfoByPlate;
  setTransfersInfoByPlate: SetTransferInfoByPlate;
  // A tree of all the transfers by "source to destination"
  // e.g. { myPlate1: { myOuput2: Array(1), myOutput1: Array(3) }, myPlate2: {...}, ... }
  transfersTree: TransfersTree;
  setTransfersTree: SetTransfersTree;
  // Each step is a "page" containing a source/dest plate couple
  activeStep: ActiveStep;
  setActiveStep: SetActiveStep;
  // If true, transfers will be single-channel, in the order specified in the csv.
  // If false, Antha will optimise transfers and multi-channel where possible.
  enforceOrder: boolean;
  setEnforceOrder: SetEnforceOrder;
  selectedDevice: ConfiguredDevice[];
  setSelectedDevice: SetSelectedDevice;
  // We keep track of the latest saved workflow so that in case there are changes to it,
  // we save it to the DB (update the existing one). Used in the Autosave component.
  lastSavedWorkflow: SuccessfullySavedWorkflow | null;
  setLastSavedWorkflow: SetLastSavedWorkflow;
  workflowName: string;
  setWorkflowName: SetWorkflowName;
  isSavingWorkflow: boolean;
  setIsSavingWorkflow: SetIsSavingWorkflow;
  isReadonly: boolean;
  setIsReadonly: Dispatch<SetStateAction<boolean>>;
  isInitialLoading: boolean;
};

const throwNoContextRegistered = () => {
  throw new Error('No CherryPickContext provider registered.');
};

export const DEFAULT_ACTIVE_STEP: ActiveStep = { source: 0, destination: 0 };

const DEFAULT_CONTEXT: CherryPickContext = {
  cherryPick: [],
  setCherryPick: throwNoContextRegistered,
  uniqueLiquidNames: [],
  uniqueDestinationPlateNames: [],
  uniquePlateNames: [],
  platesByName: {},
  setPlatesByName: throwNoContextRegistered,
  bundleConfig: defaultWorkflowConfig(),
  setBundleConfig: throwNoContextRegistered,
  allPlatesByType: {},
  uploadedFileName: null,
  setUploadedFileName: throwNoContextRegistered,
  plateMinSizeByName: {},
  setPlateMinSizeByName: throwNoContextRegistered,
  transfersInfoByPlate: {},
  setTransfersInfoByPlate: throwNoContextRegistered,
  transfersTree: {},
  setTransfersTree: throwNoContextRegistered,
  activeStep: DEFAULT_ACTIVE_STEP,
  setActiveStep: throwNoContextRegistered,
  enforceOrder: false,
  setEnforceOrder: throwNoContextRegistered,
  selectedDevice: [],
  setSelectedDevice: throwNoContextRegistered,
  lastSavedWorkflow: null,
  setLastSavedWorkflow: throwNoContextRegistered,
  workflowName: DEFAULT_CHERRY_PICK_WORKFLOW_NAME,
  setWorkflowName: throwNoContextRegistered,
  isSavingWorkflow: false,
  setIsSavingWorkflow: throwNoContextRegistered,
  isReadonly: false,
  setIsReadonly: throwNoContextRegistered,
  isInitialLoading: false,
};

const CherryPickContext = React.createContext<CherryPickContext>(DEFAULT_CONTEXT);

export function useCherryPickContext() {
  return useContext(CherryPickContext);
}

type CherryPickContextProps = {
  children: React.ReactNode;
};

export default function CherryPickContextProvider(props: CherryPickContextProps) {
  const { children } = props;

  const [lastSavedWorkflow, setLastSavedWorkflow] =
    useState<SuccessfullySavedWorkflow | null>(null);
  const [cherryPick, setCherryPick] = useState<LiquidTransfer[]>([]);
  const [platesByName, setPlatesByName] = useState<PlatesByName>({});
  const [bundleConfig, setBundleConfig] = useState<WorkflowConfig>(
    defaultWorkflowConfig(),
  );
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [plateMinSizeByName, setPlateMinSizeByName] = useState<PlateMinSizeByName>({});
  const [transfersInfoByPlate, setTransfersInfoByPlate] = useState<TransfersInfoByPlate>(
    {},
  );
  const [transfersTree, setTransfersTree] = useState<TransfersTree>({});
  const [activeStep, setActiveStep] = useState<ActiveStep>(DEFAULT_ACTIVE_STEP);
  const [enforceOrder, setEnforceOrder] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<ConfiguredDevice[]>([]);
  const [workflowName, setWorkflowName] = useState(DEFAULT_CHERRY_PICK_WORKFLOW_NAME);
  const [isSavingWorkflow, setIsSavingWorkflow] = useState(false);
  const [isReadonly, setIsReadonly] = useState(false);

  const [allPlatesByType, isInitialLoading] = usePlatesByType();
  const { uniqueLiquidNames, uniqueDestinationPlateNames, uniquePlateNames } =
    useMemo(() => {
      const uniqueSourcePlateNames = [
        ...new Set(cherryPick.map(step => step.sourcePlate)),
      ];
      const uniqueDestinationPlateNames = [
        ...new Set(cherryPick.map(step => step.destinationPlate)),
      ];
      const uniquePlateNames = [
        ...new Set(uniqueSourcePlateNames.concat(uniqueDestinationPlateNames)),
      ];
      return {
        uniqueLiquidNames: [
          ...new Set(
            // Filter out transfers with 0 or less volume. These aren't valid and hence
            // shouldn't be inlcuded in this set.
            cherryPick.filter(t => t.transferVolume.value > 0).map(step => step.liquid),
          ),
        ],
        uniqueDestinationPlateNames,
        uniquePlateNames,
      };
    }, [cherryPick]);

  const ctx = useMemo<CherryPickContext>(
    () => ({
      cherryPick,
      setCherryPick,
      uniqueLiquidNames,
      uniqueDestinationPlateNames,
      uniquePlateNames,
      platesByName,
      setPlatesByName,
      bundleConfig,
      setBundleConfig,
      allPlatesByType,
      uploadedFileName,
      setUploadedFileName,
      plateMinSizeByName,
      setPlateMinSizeByName,
      transfersInfoByPlate,
      setTransfersInfoByPlate,
      transfersTree,
      setTransfersTree,
      activeStep,
      setActiveStep,
      enforceOrder,
      setEnforceOrder,
      selectedDevice,
      setSelectedDevice,
      lastSavedWorkflow,
      setLastSavedWorkflow,
      workflowName,
      setWorkflowName,
      isSavingWorkflow,
      setIsSavingWorkflow,
      isReadonly,
      setIsReadonly,
      isInitialLoading,
    }),
    [
      cherryPick,
      uniqueLiquidNames,
      uniqueDestinationPlateNames,
      uniquePlateNames,
      platesByName,
      bundleConfig,
      allPlatesByType,
      uploadedFileName,
      plateMinSizeByName,
      transfersInfoByPlate,
      transfersTree,
      activeStep,
      enforceOrder,
      selectedDevice,
      lastSavedWorkflow,
      workflowName,
      isSavingWorkflow,
      isReadonly,
      isInitialLoading,
    ],
  );

  return <CherryPickContext.Provider value={ctx}>{children}</CherryPickContext.Provider>;
}
