import Ajv from 'ajv';
import { CastingFunction, ColumnOption } from 'csv-parse';
import csvParse from 'csv-parse/lib/sync';

import {
  AllPlatesByType,
  CherryPickContext,
  PlatesByName,
  SetBundleConfig,
  SetCherryPick,
  SetPlatesByName,
  SetWorkflowName,
} from 'client/app/apps/cherry-picker/CherryPickContext';
import { schema } from 'client/app/apps/cherry-picker/cp-file-upload/CherryPickJsonSchema';
import { InvalidPicklistError } from 'client/app/apps/cherry-picker/errorsHelper';
import splitFullPlateName from 'client/app/components/Parameters/PlateType/splitFullPlateName';
import { SuccessfullySavedWorkflow } from 'client/app/lib/workflow/SuccessfullySavedWorkflow';
import { isDefined } from 'common/lib/data';
import { downloadTextFile } from 'common/lib/download';
import {
  getColumnNumberFromWellPosition,
  getRowNumberFromWellPosition,
} from 'common/lib/format';
import {
  ConfiguredDevice,
  ServerSideBundle,
  WorkflowConfig,
} from 'common/types/bundle';
import { updateConfigAfterSet } from 'common/types/bundleConfigUtils';
import { Measurement, PlateContentsMatrix } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';

const DEFAULT_DEST_PLATE_NAME = 'Destination Plate';
const DEFAULT_LIQUID = 'Liquid';
const DEFAULT_POLICY = 'water';
const DEFAULT_SOURCE_PLATE_NAME = 'Source Plate';
const DEFAULT_UNIT = 'ul';
const DEFAULT_WELL = 'A1';
export const DEFAULT_SOURCE_VOLUME: Measurement = {
  value: 0,
  unit: DEFAULT_UNIT,
};
const DEFAULT_TRANSFER_VOLUME: Measurement = { value: 0, unit: DEFAULT_UNIT };
export const DEFAULT_TRANSFER: LiquidTransfer = {
  destinationPlate: DEFAULT_DEST_PLATE_NAME,
  destinationWell: DEFAULT_WELL,
  liquid: DEFAULT_LIQUID,
  policy: DEFAULT_POLICY,
  sourcePlate: DEFAULT_SOURCE_PLATE_NAME,
  sourceVolume: DEFAULT_SOURCE_VOLUME,
  sourceWell: DEFAULT_WELL,
  transferOrder: 1,
  transferVolume: DEFAULT_TRANSFER_VOLUME,
};

export type LiquidTransfer = {
  // In some cases, we will shuffle the transfers order (e.g. if we are
  // displaying transfers by plate).We need an index to show users the
  // actual temporal order in which transfers will happen.
  transferOrder: number;
  // Source volume is optional: If left blank, Antha will calculate it.
  // Users can fill this information in via the UI or specify it in the csv
  sourceVolume?: Measurement;
  // User defined label for a plate (e.g. myPlate1)
  sourcePlate: string;
  destinationPlate: string;
  // A1, A2, NX
  sourceWell: string;
  destinationWell: string;
  // We assume unit is uL by default
  transferVolume: Measurement;
  // E.g. water, DNA, etc.
  liquid: string;
  // Liquid Handling policy Antha needs
  policy: string;
};

// Perform some validation on the csv users upload
function createValueValidator(property: CherryPickCSVTemplate): CastingFunction {
  return (value, { column, header, index }) => {
    if (!column || header) {
      return value;
    }

    if (value === null || value === undefined) {
      return null;
    }

    // Get the type for validation purposes
    const type = property[column];

    value = value.trim();

    // For v1, we will have stricter checks. I.e. not allow empty fields
    if (value.length === 0) {
      throw new Error(`Missing value in column ${column}, row ${index + 1}.`);
    }

    if (type === 'number') {
      const parsed = Number(value);
      if (isNaN(parsed)) {
        throw new Error(
          `Value '${value}' in column ${column} (Row ${index + 1}) is not a number.`,
        );
      }
      return parsed;
    }

    return value;
  };
}

const SOURCE_PLATE_NAME = 'Source Plate Name';
const SOURCE_PLATE_TYPE = 'Source Plate Type';
const SOURCE_WELL = 'Source Well';
const SOURCE_LIQUID_NAME = 'Source Liquid Name';
const SOURCE_LIQUID_POLICY = 'Source Liquid Policy';
const SOURCE_VOLUME = 'Source Volume';
const TRANSFER_VOLUME = 'Transfer Volume';
const TRANSFER_LIQUID_POLICY = 'Transfer Liquid Policy';
const DESTINATION_PLATE_NAME = 'Destination Plate Name';
const DESTINATION_PLATE_TYPE = 'Destination Plate Type';
const DESTINATION_WELL = 'Destination Well';
// The csv only needs to have these columns.
// These are used to render the dropdown which allows
// users to map their headers with the standard ones UI
// knows about.
export const requiredCsvHeaders = [
  SOURCE_PLATE_NAME,
  SOURCE_WELL,
  SOURCE_LIQUID_NAME,
  TRANSFER_VOLUME,
  DESTINATION_PLATE_NAME,
  DESTINATION_WELL,
];
export const optionalCsvHeaders = [SOURCE_VOLUME, SOURCE_LIQUID_POLICY];
type CherryPickCSVTemplate = { [property: string]: 'string' | 'number' };
// Remember to update the TEMPLATE_ROW below if making changes to this object.
const CHERRY_PICK_CSV_TEMPLATE: CherryPickCSVTemplate = {
  // Key: case sensitive header parsed from the csv;
  // Value: Expected type. Used to do some checking/validation;
  [SOURCE_PLATE_NAME]: 'string',
  [SOURCE_VOLUME]: 'number',
  [SOURCE_WELL]: 'string',
  [SOURCE_LIQUID_NAME]: 'string',
  [SOURCE_LIQUID_POLICY]: 'string',
  [DESTINATION_PLATE_NAME]: 'string',
  [TRANSFER_VOLUME]: 'number',
  [DESTINATION_WELL]: 'string',
};
// Build a simple template as a starting point for users to download.
const TEMPLATE_ROW = `dummySourcePlate,75,A1,Dummy Liquid,${DEFAULT_POLICY},dummyDestinationPlate,50,A2`;
const TEMPLATE_HEADER = Object.keys(CHERRY_PICK_CSV_TEMPLATE).join(',');
const CSV_TEMPLATE = `${TEMPLATE_HEADER}\n${TEMPLATE_ROW}`;
const TEMPLATE_FILENAME = 'CherryPickerTemplate.csv';

export function downloadCSVTemplate() {
  downloadTextFile(CSV_TEMPLATE, TEMPLATE_FILENAME);
}

const columnsValidator = (headers: string[]): ColumnOption[] => {
  for (const columnName of headers) {
    // Check users specified at least N columns (based on the required headers).
    // Having more is valid, i.e. if they specified the optional header.
    if (headers.length < Object.keys(CHERRY_PICK_CSV_TEMPLATE).length) {
      throw new Error(
        `Your csv does not contain the required columns. Please check that the CherryPick list follows the right format.`,
      );
    }

    if (columnName === '') {
      throw new Error(
        `A column in your CSV file is missing the header. Please check your file and try again.`,
      );
    }

    // Check if all the columns follow the nomenclature we expect
    if (!CHERRY_PICK_CSV_TEMPLATE[columnName]) {
      throw new Error(
        `Unexpected column ${columnName}. Please manually match the headers using the pop-up dialog.`,
      );
    }
  }
  return headers;
};

const castFn = createValueValidator(CHERRY_PICK_CSV_TEMPLATE);

/** If uploaded csv passes these strict checks (e.g. perfectly matching headers)
 *  we can safely render the UI without further changes to the parsed lines.
 */
function parseCsvStrictChecks(csvData: string) {
  let parsed = [];
  try {
    parsed = csvParse(csvData, {
      columns: columnsValidator,
      cast: castFn,
      skip_empty_lines: true,
    });
    return parsed;
  } catch (error) {
    return null;
  }
}

type ParsedCsv = { [csvHeader: string]: string }[];
/** To allow flexibility of the input, we parse the CSV without performing
 *  any checks. We'll prompt users to map their headers with the ones the
 *  UI accepts.
 */
function parseCsvNoChecks(csvData: string) {
  const parsed: ParsedCsv = csvParse(csvData, {
    skip_empty_lines: true,
    trim: true,
    columns: true,
  });

  return parsed;
}

/** If users upload a csv in the right format, use it directly.
 *  Otherwise, parse it without checks.
 *  Returns `[parsedCsv, passedStrictChecks]`.
 */
export function getDataFromCsv(csvData: string): [ParsedCsv, boolean] {
  const parsedStrict = parseCsvStrictChecks(csvData);
  if (!parsedStrict) {
    return [parseCsvNoChecks(csvData), false];
  }

  return [parsedStrict, true];
}

export function cherryPickDataFromParsedCsv(parsed: ParsedCsv): LiquidTransfer[] {
  let row = 0;
  const liquidTransfers: LiquidTransfer[] = [];
  for (const parsedLine of parsed) {
    const {
      [DESTINATION_PLATE_NAME]: destinationPlate,
      [DESTINATION_WELL]: destinationWell,
      [SOURCE_VOLUME]: sourceVol,
      [SOURCE_LIQUID_NAME]: liquid,
      [SOURCE_LIQUID_POLICY]: sourcePolicy,
      [SOURCE_PLATE_NAME]: sourcePlate,
      [SOURCE_WELL]: sourceWell,
      [TRANSFER_VOLUME]: volume,
    } = parsedLine;

    const policy = sourcePolicy ? sourcePolicy : DEFAULT_POLICY;
    let sourceVolume = DEFAULT_SOURCE_VOLUME;
    // Use users' specified source volume, if any.
    if (sourceVol) {
      sourceVolume = { value: Number(sourceVol), unit: 'ul' };
    }

    liquidTransfers.push({
      transferOrder: ++row,
      sourceVolume,
      sourcePlate,
      destinationPlate,
      sourceWell,
      destinationWell,
      transferVolume: { value: Number(volume), unit: 'ul' },
      liquid,
      policy,
    });
  }

  return liquidTransfers;
}

// We need to describe the wells' content for each plate
// to show meaningful information in the UI (e.g. colour coded wells)
// Example of single well to well transfer
// myPlate: {
// wellsContentByPosition: { A1: 'water' },
// plateOutputs: { myOutput: { A1: 'water' } }
// }
export type TransfersInfoByPlate = {
  [plateName: string]: {
    wellsContentByPosition: WellsContentByPosition;
    plateOutputs: ContentsByPlate;
  };
};
export type WellsContentByPosition = { [wellPosition: string]: string };
type ContentsByPlate = { [plateName: string]: WellsContentByPosition };

export function getAllPlatesContent(cherryPick: LiquidTransfer[]) {
  const contentsByPlate: TransfersInfoByPlate = {};
  for (const row of cherryPick) {
    // Initialise source plate
    if (!contentsByPlate[row.sourcePlate]) {
      contentsByPlate[row.sourcePlate] = {
        wellsContentByPosition: {},
        plateOutputs: {},
      };
    }
    // Add liquid information to previously initialised source plate
    contentsByPlate[row.sourcePlate].wellsContentByPosition[row.sourceWell] = row.liquid;

    // Initialise destination plate
    if (!contentsByPlate[row.sourcePlate].plateOutputs[row.destinationPlate]) {
      contentsByPlate[row.sourcePlate].plateOutputs[row.destinationPlate] = {};
    }
    // Add liquid information to previously initialised destination plate
    contentsByPlate[row.sourcePlate].plateOutputs[row.destinationPlate][
      row.destinationWell
    ] = row.liquid;
  }

  return contentsByPlate;
}

// Just like `Amount`, but with capitalized fields.
type SerialisedAmount = { Value: number; Unit: string };

const name = 'Name';
const concentration = 'Concentration';
const subComponents = 'SubComponents';
type Liquid = {
  // E.g. water, Liquid A
  [name]: string;
  // Concentration and SubComponents are Antha lingo.
  // Users don't need to know about these in v1, but
  // the Cherry Pick elements needs them.
  [concentration]: SerialisedAmount;
  [subComponents]: Liquid[];
};

export type PickList = SerialisedCherryPickTransfer[];
type SerialisedCherryPickTransfer = {
  // e.g. MyPlateA
  [SOURCE_PLATE_NAME]: string;
  // e.g. costar48well
  [SOURCE_PLATE_TYPE]: string;
  // e.g. A1
  [SOURCE_WELL]: string;
  // e.g. Liquid A
  [SOURCE_LIQUID_NAME]: string;
  // If undefined, Antha will compute the source volume
  [SOURCE_VOLUME]?: SerialisedAmount;
  [TRANSFER_VOLUME]: SerialisedAmount;
  // e.g. SmartMix
  [TRANSFER_LIQUID_POLICY]: string;
  [DESTINATION_PLATE_NAME]: string;
  [DESTINATION_PLATE_TYPE]: string;
  [DESTINATION_WELL]: string;
};

const SCHEMA_VERSION = '1.1';
const SCHEMA_TYPE = 'CherryPicker';
const schemaVersion = 'SchemaVersion';
const schemaType = 'SchemaType';
const enforceOrder = 'EnforceOrder';
const liquids = 'Liquids';
const pickList = 'PickList';
// This schema represents what the Cherry Pick Element expects to receive as input.
export type CherryPickElementSchema = {
  [schemaVersion]: string;
  [schemaType]: typeof SCHEMA_TYPE;
  [liquids]: Liquid[];
  [pickList]: PickList;
  [enforceOrder]: boolean;
};

// Convert the information we have in the Context to match the Cherry Pick
// Element's `PickList` format.
export function serialiseCherryPick(
  cherryPick: LiquidTransfer[],
  platesByName: PlatesByName,
  bundleConfig: WorkflowConfig,
) {
  const platesByNameWithRisers = autoAddRiserToPlates(platesByName, bundleConfig);
  const picklist: PickList = [];
  for (const t of cherryPick) {
    const transfer = {} as SerialisedCherryPickTransfer;
    // Don't add the transfer if the transfer's volume is not positive,
    // because this will have the simulation fail.
    // By design, the Cherry Picker UI will display 0uL transfers differently, to show users
    // that these will be ignored when clicking on "Simulate". (T2037)
    if (t.transferVolume.value <= 0) {
      continue;
    }

    // Don't add the source volume if its value hasn't been set by users
    if (t.sourceVolume && t.sourceVolume.value > 0) {
      transfer[SOURCE_VOLUME] = {
        Value: t.sourceVolume.value,
        Unit: t.sourceVolume.unit,
      };
    }
    transfer[SOURCE_PLATE_NAME] = t.sourcePlate;
    transfer[SOURCE_WELL] = t.sourceWell;
    transfer[SOURCE_PLATE_TYPE] = platesByNameWithRisers[t.sourcePlate]?.type ?? '';
    transfer[DESTINATION_PLATE_NAME] = t.destinationPlate;
    transfer[DESTINATION_WELL] = t.destinationWell;
    transfer[DESTINATION_PLATE_TYPE] =
      platesByNameWithRisers[t.destinationPlate]?.type ?? '';
    transfer[SOURCE_LIQUID_NAME] = t.liquid;
    transfer[TRANSFER_VOLUME] = {
      Value: t.transferVolume.value,
      Unit: t.transferVolume.unit,
    };
    transfer[TRANSFER_LIQUID_POLICY] = t.policy;

    picklist.push(transfer);
  }

  return picklist;
}

export function deserialisePickList(pickList: PickList) {
  const cherryPick: LiquidTransfer[] = [];
  let transferOrder = 1;
  for (const t of pickList) {
    const transfer = {} as LiquidTransfer;
    transfer.transferOrder = transferOrder++;
    transfer.sourcePlate = t[SOURCE_PLATE_NAME];
    transfer.sourceWell = t[SOURCE_WELL];
    transfer.destinationPlate = t[DESTINATION_PLATE_NAME];
    transfer.destinationWell = t[DESTINATION_WELL];
    transfer.liquid = t[SOURCE_LIQUID_NAME];
    transfer.transferVolume = {
      value: t[TRANSFER_VOLUME].Value,
      unit: t[TRANSFER_VOLUME].Unit,
    };
    transfer.sourceVolume = {
      value: t[SOURCE_VOLUME]?.Value ?? 0,
      unit: t[SOURCE_VOLUME]?.Unit ?? DEFAULT_UNIT,
    };
    transfer.policy = t[TRANSFER_LIQUID_POLICY];

    cherryPick.push(transfer);
  }

  return cherryPick;
}

// For v1, we won't allow users to specify a concentration for their liquids.
// For our purposes, it's fine to just use a default "1X".
const DEFAULT_CONCENTRATION = { Value: 1, Unit: 'X' };

export function serialiseLiquids(liquidNames: string[]) {
  const liquids: Liquid[] = [];

  for (const liquidName of liquidNames) {
    const liquid = {} as Liquid;
    liquid[name] = liquidName;
    liquid[concentration] = DEFAULT_CONCENTRATION;
    // In v1, we won't allow users to select subComponents.
    liquid[subComponents] = [];
    liquids.push(liquid);
  }

  return liquids;
}

// Get the complete JSON that matches the Cherry Pick Element schema
export function getSerialisedJson(
  cherryPick: LiquidTransfer[],
  platesByName: PlatesByName,
  liquidList: Liquid[],
  enforceTransferOrder: boolean,
  bundleConfig: WorkflowConfig,
) {
  const serialisedJson = {} as CherryPickElementSchema;
  serialisedJson[schemaType] = SCHEMA_TYPE;
  serialisedJson[schemaVersion] = SCHEMA_VERSION;
  serialisedJson[pickList] = serialiseCherryPick(cherryPick, platesByName, bundleConfig);
  serialisedJson[liquids] = liquidList;
  serialisedJson[enforceOrder] = enforceTransferOrder;

  // To avoid breaking this code accidentally, we also
  // validate the JSON against a schema that doesn't live in
  // this file
  try {
    validateAgainstSchema(serialisedJson);
  } catch (error) {
    throw new Error(error);
  }

  return serialisedJson;
}

export const DEFAULT_CHERRY_PICK_WORKFLOW_NAME = 'Cherry Picker Workflow';
const CHERRY_PICK_ELEMENT_NAME = 'Cherry_Pick_From_Well_To_Well';
const DEFAULT_METADATA = { x: 100, y: 100 };
const DEFINE_TRANSFERS_FROM_GUI_EDITOR = 'guiEditor';

/**
 * Given a workflow loaded from the DB, it updates it with the changes made
 * by the users. This is done by taking information in the React Context and
 * merging it with the data we already are storing in the DB.
 *
 * The bundle for v1 of the CherryPick is very simple: it only has one element,
 * which has no connections and it only has one parameter filled, the TransferData.
 *
 * @param newBundleConfig The bundle config from the Context. Holds things like device, tip types, etc.
 * @param newSerialisedCherryPickJson The value of the TrasferData parameter of the Cherry Picker Well to  Well element.
 * @param selectedDevice The currently selected device.
 * @param lastSavedWorkflow The last successfully saved workflow. Used to edit/update the right Cherry Picker workflow.
 */
export function updateCherryPickBundle(
  lastSavedWorkflow: SuccessfullySavedWorkflow,
  newWorkflowName: string,
  newBundleConfig: WorkflowConfig,
  newSelectedDevice: ConfiguredDevice[],
  newSerialisedCherryPickJson: CherryPickElementSchema,
): ServerSideBundle {
  const deviceFromBundle = newBundleConfig.configuredDevices ?? [];
  // If users selected a device, use it in the simulation. Otherwise, use
  // the device that was in the Config (from a previous simulation).
  const deviceToSimulateWith = newSelectedDevice.length === 0
    ? deviceFromBundle
    : newSelectedDevice;

  // If we're loading a bundle, we already have the instance.
  const existingElementInstance =
    lastSavedWorkflow.bundle.Elements.Instances[CHERRY_PICK_ELEMENT_NAME];

  const cherryPickElementInstance = {
    Id: existingElementInstance?.Id,
    TypeName: CHERRY_PICK_ELEMENT_NAME,
    Meta: DEFAULT_METADATA,
    Parameters: {
      TransferData: newSerialisedCherryPickJson,
      // In cherry picker editor mode we need to define the (unset)
      // DefineTransfersFrom dropdown option to guiEditor
      DefineTransfersFrom: DEFINE_TRANSFERS_FROM_GUI_EDITOR,
    },
  };

  const config: WorkflowConfig = {
    ...newBundleConfig,
    configuredDevices: deviceToSimulateWith,
  }

  return {
    WorkflowId: lastSavedWorkflow.workflowId,
    Config: updateConfigAfterSet(config),
    elementSetId: lastSavedWorkflow.bundle.elementSetId,
    Elements: {
      Instances: {
        [CHERRY_PICK_ELEMENT_NAME]: cherryPickElementInstance,
      },
      InstancesConnections: [],
    },
    Meta: {
      Name: newWorkflowName || lastSavedWorkflow.bundle.Meta.Name,
    },
    Repositories: lastSavedWorkflow.bundle.Repositories,
    SchemaVersion: lastSavedWorkflow.bundle.SchemaVersion,
  };
}

/** Validate the UI generated Cherry Pick JSON against
 * a JSON schema known to both UI and element code.
 */
function validateAgainstSchema(jsonToValidate: Object) {
  const ajv = new Ajv();
  const valid = ajv.validate(schema, jsonToValidate);
  if (!valid) {
    throw new Error(ajv.errorsText());
  }
}

/** Fill the UI from a bundle. E.g. when loading a bundle
 * from the db.
 */
export function loadCherryPickFromBundle(
  bundle: ServerSideBundle,
  setBundleConfig: SetBundleConfig,
  setCherryPick: SetCherryPick,
  setWorkflowName: SetWorkflowName,
  setPlatesByName: SetPlatesByName,
  allPlatesByType: AllPlatesByType,
) {
  // Restore transfers
  const pickList = getPickListFromBundle(bundle);
  if (pickList.length === 0) {
    throw new InvalidPicklistError(
      'Loaded bundle doesn\'t have Cherry Picker "TransferData".',
    );
  }
  const transfers = deserialisePickList(pickList);
  setCherryPick(transfers);

  // Restore platesByName
  const platesByName = getPlateTypesFromBundle(bundle, allPlatesByType);
  setPlatesByName(platesByName);

  // Restore Cherry Picker settings
  setBundleConfig(bundle.Config);

  // Restore user defined workflow name
  setWorkflowName(bundle.Meta.Name);
}

function getPickListFromBundle(bundle: ServerSideBundle) {
  let pickList = [] as PickList;
  const cherryPickerElement = bundle.Elements.Instances[CHERRY_PICK_ELEMENT_NAME];
  // Get`TransferData`, if bundle has a Cherry Pick element which specified that.
  if (cherryPickerElement) {
    pickList = cherryPickerElement.Parameters.TransferData.PickList;
  }
  return pickList;
}

function getPlateTypesFromBundle(
  bundle: ServerSideBundle,
  allPlatesByType: AllPlatesByType,
) {
  const pickList = getPickListFromBundle(bundle);
  const platesByName = getPlatesFromPickList(pickList, allPlatesByType);
  return platesByName;
}

function getPlatesFromPickList(pickList: PickList, allPlatesByType: AllPlatesByType) {
  const platesByName: PlatesByName = {};
  for (const t of pickList) {
    const sourcePName = t[SOURCE_PLATE_NAME];
    const sourcePlateAlreadyAdded = !!platesByName[sourcePName];
    if (!sourcePlateAlreadyAdded) {
      // We need to remove the riser from the plate type, in case one was added.
      const sourcePType = splitFullPlateName(t[SOURCE_PLATE_TYPE])[0];
      platesByName[sourcePName] = allPlatesByType[sourcePType];
    }

    const destPName = t[DESTINATION_PLATE_NAME];
    const destPlateAlreadyAdded = !!platesByName[destPName];
    if (!destPlateAlreadyAdded) {
      const destPType = splitFullPlateName(t[DESTINATION_PLATE_TYPE])[0];
      platesByName[destPName] = allPlatesByType[destPType];
    }
  }

  return platesByName;
}

export function loadCherryPickFromTransferData(
  transferData: CherryPickElementSchema,
  {
    setCherryPick,
    setPlatesByName,
    allPlatesByType,
  }: // Only passing the needed props to avoid unnecessary re-renders
    Pick<CherryPickContext, 'setCherryPick' | 'setPlatesByName' | 'allPlatesByType'>,
) {
  const transfers = deserialisePickList(transferData[pickList]);
  // Safety net: In case the picklist is empty, use the default transfer so
  // the UI can still be rendered.
  const safeValue = transfers.length === 0 ? [DEFAULT_TRANSFER] : transfers;
  setCherryPick(safeValue);

  const platesByName = getPlatesFromPickList(transferData[pickList], allPlatesByType);
  setPlatesByName(platesByName);
}

type MinSize = { minRows: number; minCols: number };
export type PlateMinSizeByName = { [plateName: string]: MinSize };
/** If users specify e.g. well "C12" in their picklist we know that the plate
 * they select *must* have at least 12 columns, and 3 rows. This way we can
 * prevent users from simulating against an obiously wrong plate.
 */
export function getMinimumPlatesSize(cherryPick: LiquidTransfer[]) {
  const plateMinSizeByName: PlateMinSizeByName = {};
  for (const t of cherryPick) {
    // Initialise plate if we haven't encountered it yet.
    if (!plateMinSizeByName[t.sourcePlate]) {
      plateMinSizeByName[t.sourcePlate] = { minCols: 0, minRows: 0 };
    }
    // +1 as internally we deal with 0-indexed lists, but the UI should show
    //  1-indexed lists, which is how users think (i.e. first well should be 1, not 0)
    const sourceCol = getColumnNumberFromWellPosition(t.sourceWell) + 1;
    if (plateMinSizeByName[t.sourcePlate].minCols < sourceCol) {
      plateMinSizeByName[t.sourcePlate].minCols = sourceCol;
    }
    const sourceRow = getRowNumberFromWellPosition(t.sourceWell) + 1;
    if (plateMinSizeByName[t.sourcePlate].minRows < sourceRow) {
      plateMinSizeByName[t.sourcePlate].minRows = sourceRow;
    }

    // Initialise plate if we haven't encountered it yet.
    if (!plateMinSizeByName[t.destinationPlate]) {
      plateMinSizeByName[t.destinationPlate] = { minCols: 0, minRows: 0 };
    }
    const destCol = getColumnNumberFromWellPosition(t.destinationWell) + 1;
    if (plateMinSizeByName[t.destinationPlate].minCols < destCol) {
      plateMinSizeByName[t.destinationPlate].minCols = destCol;
    }

    const destRow = getRowNumberFromWellPosition(t.destinationWell) + 1;
    if (plateMinSizeByName[t.destinationPlate].minRows < destRow) {
      plateMinSizeByName[t.destinationPlate].minRows = destRow;
    }
  }

  return plateMinSizeByName;
}

/** Check if the plate has enough rows/cols. Used to prevent users from selecting
 *  a plate which we know will not simulate correctly.
 */
export function isPlateBigEnough(
  plate: PlateType,
  plateName: string,
  plateMinSizeByName: PlateMinSizeByName,
) {
  const plateHasEnoughCols = plate.columns >= plateMinSizeByName[plateName].minCols;
  if (!plateHasEnoughCols) {
    return `The "${plate.type}" you selected has ${plate.columns} column(s).
      Your picklist defines a transfer for this plate on column ${plateMinSizeByName[plateName].minCols}.
      Please select a plate with at least ${plateMinSizeByName[plateName].minCols} columns.`;
  }

  const plateHasEnoughRows = plate.rows >= plateMinSizeByName[plateName].minRows;
  if (!plateHasEnoughRows) {
    return `The "${plate.type}" you selected has ${plate.rows} row(s).
      Your picklist defines a transfer for this plate on row ${plateMinSizeByName[plateName].minRows}.
      Please select a plate with at least ${plateMinSizeByName[plateName].minRows} rows.`;
  }

  // Plate is OK, don't return an error message.
  return undefined;
}

// Given a source plate, return all the destination plates it transfers to.
// E.g. "myPlate" might return `[myOut1, myOut2]`
function getOutputPlatesFromSourcePlate(
  sourcePlate: string,
  transfersInfoByPlate: TransfersInfoByPlate,
) {
  const possibleOutputs = transfersInfoByPlate[sourcePlate]?.plateOutputs;
  return possibleOutputs && Object.keys(possibleOutputs).sort();
}

// Given a source plate, return all destination plates and their relative
// transfers.
// E.g. "myPlate" might return `{ myOut2: Array(10), myOut2: Array(2) }`
type TransfersForSourceByDest = { [destPlateName: string]: LiquidTransfer[] };
function getTransfersForSourceByDest(
  cherryPick: LiquidTransfer[],
  sourcePlate: string,
  transfersInfoByPlate: TransfersInfoByPlate,
) {
  const possibleOutputPlates = getOutputPlatesFromSourcePlate(
    sourcePlate,
    transfersInfoByPlate,
  );
  const transfersForSourceByDest: TransfersForSourceByDest = {};
  for (const destPlate of possibleOutputPlates) {
    if (!transfersForSourceByDest[destPlate]) {
      transfersForSourceByDest[destPlate] = [];
    }
    const transfersToSpecificPlate = cherryPick.filter(
      transfer =>
        // Only transfers to a specific destination plate
        transfer.destinationPlate === destPlate &&
        // Coming from a specific source plate
        transfer.sourcePlate === sourcePlate,
    );

    transfersForSourceByDest[destPlate] = transfersToSpecificPlate;
  }
  return transfersForSourceByDest;
}

// Main representation of data, used to populate the table and
// and plate visualisation.
// e.g. { myPlate1: { myOuput2: Array(1), myOutput1: Array(3) }, myOut2: { ... }, ... }
export type TransfersTree = {
  [sourcePlate: string]: { [destPlate: string]: LiquidTransfer[] };
};

export function getTransfersTree(
  cherryPick: LiquidTransfer[],
  transfersInfoByPlate: TransfersInfoByPlate,
) {
  // Compute transfers which apply to a specific destination plate and
  // store them in a map to avoid recomputing every time a user selects
  // a different plate.
  const sourceToDest: TransfersTree = {};
  for (const sourcePlate of Object.keys(transfersInfoByPlate)) {
    sourceToDest[sourcePlate] = getTransfersForSourceByDest(
      cherryPick,
      sourcePlate,
      transfersInfoByPlate,
    );
  }
  return sourceToDest;
}

/** Check if users defined an Antha plate type (e.g. costar48wells) for
 *  every plate they defined in the cherrypicker.csv (e.g. myPlate1)
 */
export function areAllPlateTypesSelected(
  platesByName: PlatesByName,
  uniquePlateNames: string[],
) {
  const platesDefined = Object.keys(platesByName).filter(plateName =>
    // Filter out plates that have been deleted
    uniquePlateNames.includes(plateName),
  );
  const totalNumberOfPlates = uniquePlateNames.length;
  return (
    platesDefined.length === totalNumberOfPlates && platesDefined.every(plate => !!plate)
  );
}

/**
 *  Check if there is at least one transfer which is valid (i.e. with >0ul transfer).
 *  Used to prevent users to simulate an invalid Cherry Picker.
 */
export function isAtLeastOneTransferValid(transfers: LiquidTransfer[]) {
  return transfers.some(transfer => transfer.transferVolume.value > 0);
}
export const NO_VALID_TRANSFERS_MSG =
  'Please add at least one transfer with positive volume before simulating.';

/** Transform contents from CherryPick to MixPlate datastructure. Needed to use the latter
 *  in the Cherry Picker UI.
 */
export function makeWellSelectorContents(contentsByPosition: WellsContentByPosition) {
  const contents: PlateContentsMatrix = {};
  Object.keys(contentsByPosition).forEach(wellPos => {
    const row = getRowNumberFromWellPosition(wellPos);
    const col = getColumnNumberFromWellPosition(wellPos);
    if (!contents[col]) {
      contents[col] = {};
    }
    contents[col] = contents[col] = {
      ...contents[col],
      [row]: {
        id: '',
        kind: 'liquid_summary',
        name: contentsByPosition[wellPos],
        total_volume: { value: 0, unit: '' },
      },
    };
  });
  return contents;
}

const GILSON_TIPS_THAT_NEED_RISER = ['Gilson20', 'GilsonFilter10'];
const RISER_TO_ADD = '_riser18';
/**
 * We will hide the riser selector from the UI. Instead,
 * we automatically add a riser if certain criteria are met.
 * This will change in the future, but as a first implementation it's ok.
 * Ref T605 for more context.
 */
function autoAddRiserToPlates(
  platesByName: PlatesByName,
  bundleConfig: WorkflowConfig,
) {
  const configuredDevices = bundleConfig.configuredDevices ?? [];
  // Note that in the Cherry Picker, you can only select one device.
  // Criteria 1: Simulating with Gilson. (Risers are Gilson only)
  const isSimulatingWithGilson = configuredDevices.some(cd => cd.type === 'GilsonPipetMax');
  if (!isSimulatingWithGilson) {
    return platesByName;
  }
  // Criteria 2: Tips are either 20uL or 10uL
  // Note: tip types may be specified on the devices or global, depending on whether
  //       updateConfigAfterSet() has been called or not
  const allTips = [...(bundleConfig.global.tipTypes ?? []), ...configuredDevices.flatMap(cd => cd.tipTypes)].filter(isDefined);
  const areShortTipsSelected = allTips.some(tipType =>
    GILSON_TIPS_THAT_NEED_RISER.includes(tipType),
  );
  if (!areShortTipsSelected) {
    return platesByName;
  }
  // Criteria 3: Plate height is <16mm.
  // Add a riser to each plate which is shorter than 15mm.
  const modifiedPlates: PlatesByName = {};
  for (const name in platesByName) {
    if (platesByName[name].dimension.z < 16) {
      const newPlate = { ...platesByName[name] };
      newPlate.type = newPlate.type + RISER_TO_ADD;
      modifiedPlates[name] = newPlate;
    }
  }
  return { ...platesByName, ...modifiedPlates };
}

// Antha generated plates column names
const PLATE_TYPE = 'plateType';
const PLATE_NAME = 'plateName';
const WELL_POS = 'wellPos';
const WELL_CONTENTS = 'wellContents';
const POLICY = 'policy';
const VOLUME = 'volume';
const UNIT = 'unit';

type ParsedPlateRecord = {
  [WELL_POS]: string;
  [WELL_CONTENTS]: string;
  [POLICY]: string;
  [VOLUME]: string;
  [UNIT]: string;
};
type ParsedAnthaGeneratedPlate = ParsedPlateRecord[];

type NewParsedPlateRecord = ParsedPlateRecord & {
  [PLATE_TYPE]: string;
  [PLATE_NAME]: string;
};
type NewParsedAnthaGeneratedPlate = NewParsedPlateRecord[];

const oldAnthaGeneratedPlateHeaders = [WELL_POS, WELL_CONTENTS, POLICY, VOLUME, UNIT];

const newAnthaGeneratedPlateHeaders = [
  null, // simulation ID
  PLATE_NAME,
  PLATE_TYPE,
  WELL_POS,
  null, // row
  null, // column
  WELL_CONTENTS,
  POLICY,
  VOLUME,
  UNIT,
];

/** When parsing a csv, don't inlcude rows which had an empty contents or type or no volume */
function skipLineWithEmptyValue(record: ParsedAnthaGeneratedPlate[0]) {
  if (
    !record[WELL_CONTENTS] ||
    !record[POLICY] ||
    !record[VOLUME] ||
    record[VOLUME] === '0'
  ) {
    return null;
  }
  return record;
}

export function parseAnthaPlate(
  anthaGeneratedPlate: string,
): [ParsedAnthaGeneratedPlate, string, string] {
  if (anthaGeneratedPlate.startsWith('"Simulation ID",')) {
    // This is a new-style plate file from after Jan 2021
    const parsed: NewParsedAnthaGeneratedPlate = csvParse(anthaGeneratedPlate, {
      trim: true,
      on_record: skipLineWithEmptyValue,
      columns: newAnthaGeneratedPlateHeaders,
      relax_column_count: true,
      // The first row is the headers, so throw them away
      from: 2,
    });

    return [parsed, parsed[0][PLATE_TYPE], parsed[0][PLATE_NAME]];
  } else {
    // This is an old-style plate from before Jan 2021
    const parsed: ParsedAnthaGeneratedPlate = csvParse(anthaGeneratedPlate, {
      trim: true,
      on_record: skipLineWithEmptyValue,
      columns: oldAnthaGeneratedPlateHeaders,
      // Number of headers is not consistent with rows data, e.g.
      // Conc | Cocn Unit | SubComponents   |          |
      //  0   |     g/l   | 5_part_assembly | 0.98 v/v |
      // Allow the plate to be parsed anyway.
      relax_column_count: true,
    });

    /**
     * The first line of an Antha generated plate looks like this:
     *
     * [0] <plate type> (e.g. costar48star)
     * [1] <plate name> (e.g. myPlate)
     * [2] "LiquidType" (which is liquid policy, e.g. dna)
     * [3] "Vol" (e.g. 102)
     * [4] "Val Unit" (e.g. "ul")
     * [5] "Conc" (e.g. 1)
     * [6] "Conc Unit" (e.g. g/l),
     * [7] "SubComponents" (e.g. 5_part_assembly)
     */
    const firstRow = parsed.shift();

    if (!firstRow) {
      throw new Error('Antha generated plate file is empty.');
    }

    const plateType = firstRow[WELL_POS];
    const plateName = firstRow[WELL_CONTENTS];

    return [parsed, plateType, plateName];
  }
}

export function cherryPickDataFromParsedAnthaPlate(
  parsed: ParsedAnthaGeneratedPlate,
  _plateType: string,
  plateName: string,
): LiquidTransfer[] {
  let row = 0;
  const liquidTransfers: LiquidTransfer[] = [];
  for (const parsedLine of parsed) {
    const {
      [VOLUME]: sourceVol,
      [UNIT]: sourceVolUnit,
      [WELL_CONTENTS]: liquid,
      [WELL_POS]: sourceWell,
      [POLICY]: policy,
    } = parsedLine;

    let sourceVolume = DEFAULT_SOURCE_VOLUME;
    // Use users' specified source volume, if any.
    if (sourceVol) {
      sourceVolume = { value: Number(sourceVol), unit: sourceVolUnit };
    }

    liquidTransfers.push({
      ...DEFAULT_TRANSFER,
      transferOrder: ++row,
      sourceVolume,
      sourcePlate: plateName,
      sourceWell,
      liquid,
      policy,
    });
  }

  return liquidTransfers;
}
