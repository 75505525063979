import MuiButton from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

import Button from 'common/ui/components/Button';

const BUTTON_HEIGHT = 40;

export {
  DownloadInstructionsButton,
  MarkAsCompleteButton,
  ReadInstructionsButton,
  ScheduleButton,
};

const DownloadInstructionsButton = styled(Button)({
  height: BUTTON_HEIGHT,
});

const MarkAsCompleteButton = styled(Fab)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',

  height: BUTTON_HEIGHT,
}));

const ReadInstructionsButton = styled(MuiButton)({
  height: BUTTON_HEIGHT,
}) as typeof MuiButton;

const ScheduleButton = styled(Fab)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',

  height: BUTTON_HEIGHT,
}));
