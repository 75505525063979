import React, { useRef, useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { formatDuration } from 'common/lib/format';
import { stringToMarkdown } from 'common/lib/markdown';
import { MixPreviewStages } from 'common/types/mixPreview';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';
import { useManualInterventionHighlight } from 'common/ui/components/simulation-details/mix/ManualInterventionContext';
import KeyPointPopper from 'common/ui/components/simulation-details/StepSlider/components/KeyPointPopper';
import {
  KeyPointItem,
  ManualInterventionIcon,
  ManualInterventionStandaloneIcon,
  PromptInfoIcon,
  PromptInfoStandaloneIcon,
} from 'common/ui/components/simulation-details/StepSlider/components/styles';
import {
  getKeyPointColor,
  getKeypointPosition,
} from 'common/ui/components/simulation-details/StepSlider/helpers';
import { KeyPoint } from 'common/ui/components/simulation-details/StepSlider/types';

type Props = {
  currentKeyPoint: KeyPoint | undefined;
  keyPoints: readonly KeyPoint[][];
  currentStage: number;
  stages: MixPreviewStages;
  sliderWidth: number;
};

function KeyPoints({
  currentKeyPoint,
  keyPoints,
  currentStage,
  stages,
  sliderWidth,
}: Props) {
  return (
    <>
      {keyPoints.flatMap((stageKeyPoints, stageIndex) =>
        stageKeyPoints.map(keyPoint => {
          const id = `stage-${stageIndex}-step-${keyPoint.step}`;
          const isActive =
            currentStage === stageIndex && currentKeyPoint?.step === keyPoint.step;
          const position = getKeypointPosition(
            keyPoint.step,
            stageIndex,
            sliderWidth,
            stages,
          );

          return (
            <SingleKeyPoint
              id={id}
              key={id}
              keyPoint={keyPoint}
              position={position}
              isActive={isActive}
            />
          );
        }),
      )}
    </>
  );
}

type SingleKeyPointProps = {
  id: string;
  keyPoint: KeyPoint;
  position: number;
  isActive: boolean;
};

function SingleKeyPoint({ id, keyPoint, position, isActive }: SingleKeyPointProps) {
  const isEnabledManualInterventions = useFeatureToggle('MANUAL_INTERVENTION_HIGHLIGHT');
  const isPromptAction = keyPoint.kind === 'prompt';
  const isManualIntervention = !!keyPoint.isManualIntervention;
  const manualIntervention = useManualInterventionHighlight();

  const [isHovered, setIsHovered] = useState(false);

  const anchorRef = useRef<HTMLElement | null>(null);
  const popperOpen =
    isPromptAction &&
    !!anchorRef.current &&
    (isActive || isHovered || (isManualIntervention && manualIntervention.highlight));

  const StandaloneIconComponent = isManualIntervention
    ? ManualInterventionStandaloneIcon
    : PromptInfoStandaloneIcon;

  const duration = keyPoint.popper?.duration;
  const message = keyPoint.popper?.message;

  const iconContent = (
    <Stack px={2}>
      <StandaloneIconComponent />
    </Stack>
  );
  const markdownContent = (
    <Stack maxWidth={450}>
      <Stack direction="row" alignItems="baseline" gap={3}>
        {isManualIntervention ? (
          <>
            <ManualInterventionIcon />
            <Typography
              component="span"
              variant="body1"
              fontWeight={700}
              whiteSpace="nowrap"
            >
              Manual intervention -
            </Typography>
          </>
        ) : (
          <PromptInfoIcon />
        )}
        {message && (
          <Typography component="span" variant="body1" fontWeight={400}>
            <MarkdownPreview markdown={stringToMarkdown(message)} />
          </Typography>
        )}
      </Stack>
      {duration && duration > 0 && (
        <Typography variant="body1" fontWeight={700} alignSelf="flex-end" pb={2}>
          {`Duration: ${formatDuration(duration)}`}
        </Typography>
      )}
    </Stack>
  );

  return (
    <>
      <KeyPointItem
        ref={ref => (anchorRef.current = ref)}
        style={{
          left: position,
          backgroundColor: getKeyPointColor(keyPoint),
        }}
        onPointerEnter={() => isPromptAction && setIsHovered(true)}
        onPointerLeave={() => isPromptAction && setIsHovered(false)}
      />
      {isEnabledManualInterventions && (
        <KeyPointPopper
          id={id}
          open={popperOpen}
          anchorEl={anchorRef.current}
          highlight={isManualIntervention && manualIntervention.highlight}
          content={
            isActive
              ? markdownContent
              : isHovered || manualIntervention.highlight
              ? iconContent
              : null
          }
        />
      )}
    </>
  );
}

export default React.memo(KeyPoints);
