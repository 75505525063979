import React, { useCallback, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import cx from 'classnames';
import debounce from 'lodash/debounce';

import {
  DEFAULT_SOURCE_VOLUME,
  LiquidTransfer,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import { SetCherryPick } from 'client/app/apps/cherry-picker/CherryPickContext';
import PolicyParameter from 'client/app/components/Parameters/Policy/PolicyParameter';
import { ScreenRegistry } from 'client/app/registry';
import { Measurement } from 'common/types/mix';
import { zebraRowsStyles } from 'common/ui/commonStyles';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

// E.g. `liquid` is a string, `transferOrder` is a number,
// `transferVolume` is Amount.
type CellValue = string | number | Measurement;
type KeyToUpdate = keyof LiquidTransfer;

function updateRowValueByRowIdx(
  keyToUpdate: KeyToUpdate,
  newValue: CellValue,
  allTransfers: LiquidTransfer[],
  transferIdx: number,
  handleUpdateCherryPick: SetCherryPick,
) {
  logEvent(`edit-${keyToUpdate}-from-table`, ScreenRegistry.CHERRY_PICKER);
  const newCherryPick = [...allTransfers];
  newCherryPick[transferIdx] = {
    ...newCherryPick[transferIdx],
    [keyToUpdate]: newValue,
  };
  handleUpdateCherryPick(newCherryPick);
}

const EDIT_ROW_DEBOUNCE_MS = 1000;
const debouncedEditRowValueEventLog = debounce((key: KeyToUpdate) => {
  logEvent('edit-cp-table-cell', ScreenRegistry.CHERRY_PICKER, key);
}, EDIT_ROW_DEBOUNCE_MS);

type TransferRowProps = {
  liquidTransfer: LiquidTransfer;
  allTransfers: LiquidTransfer[];
  handleUpdateCherryPick: SetCherryPick;
  rowIdx: number;
  setRowKeys: React.Dispatch<React.SetStateAction<string[]>>;
  isReadonly: boolean;
};

export const EditableTransferRow = React.memo(function EditableTransferRow(
  props: TransferRowProps,
) {
  const classes = useStyles();
  const {
    liquidTransfer: transfer,
    handleUpdateCherryPick,
    rowIdx,
    allTransfers,
    setRowKeys,
    isReadonly,
  } = props;
  const [showActionBtns, setShowActionBtns] = useState(false);

  const {
    transferVolume: { value: _transferVolumeValue, unit: transferVolumeUnit },
  } = transfer;

  let computedSourceVolume: Measurement = DEFAULT_SOURCE_VOLUME;
  if (transfer.sourceVolume) {
    const { sourceVolume } = transfer;
    computedSourceVolume = {
      value: sourceVolume.value,
      unit: sourceVolume.unit,
    };
  }

  const updatePolicy = useCallback(
    // We receive the policy value from `PolicyParameter` component
    (value: string | undefined) => {
      logEvent('change-policy-by-row', ScreenRegistry.CHERRY_PICKER);
      updateRowValueByRowIdx(
        'policy',
        value ?? '',
        allTransfers,
        transfer.transferOrder - 1,
        handleUpdateCherryPick,
      );
    },
    [allTransfers, transfer.transferOrder, handleUpdateCherryPick],
  );

  const handleUpdateValue = useCallback(
    (keyToUpdate: KeyToUpdate, newValue: CellValue) => {
      updateRowValueByRowIdx(
        keyToUpdate,
        newValue,
        allTransfers,
        transfer.transferOrder - 1,
        handleUpdateCherryPick,
      );
    },
    [allTransfers, transfer.transferOrder, handleUpdateCherryPick],
  );

  const handleShowActions = useCallback(() => {
    if (isReadonly) {
      return;
    }
    setShowActionBtns(true);
  }, [isReadonly]);

  const handleHideActions = useCallback(() => {
    setShowActionBtns(false);
  }, []);

  const handleDeleteTransfer = useCallback(() => {
    // Remove the uuid associated with this particular row
    setRowKeys(prev => {
      const newKeys = [...prev];
      newKeys.splice(rowIdx, 1);
      return newKeys;
    });
    let count = 1;
    const newTransfers = [
      ...allTransfers
        // Delete selected transfer
        .filter(t => t !== transfer)
        // Remap transferOrder so that there are no gaps left
        .map(t => {
          t.transferOrder = count++;
          return t;
        }),
    ];

    handleUpdateCherryPick(newTransfers);
  }, [allTransfers, handleUpdateCherryPick, rowIdx, setRowKeys, transfer]);

  /** If a transfer has a non-positive transfer volume, we show it
   * differently in the UI, to show users these won't be included in the simulation.
   * More context here: T2037
   */
  const isEmptyTransfer = transfer.transferVolume.value <= 0;

  return (
    <TableRow
      className={cx({
        [classes.fullRow]: true,
        [classes.incompleteTransfer]: isEmptyTransfer,
      })}
      onMouseOver={handleShowActions}
      onMouseOut={handleHideActions}
    >
      <TableCell className={classes.orderCell}>{transfer.transferOrder}</TableCell>
      <EditableCell
        transfer={transfer}
        keyToUpdate="sourceVolume"
        handleUpdateValue={handleUpdateValue}
        volumeUnit={computedSourceVolume.unit}
        isDisabled={isReadonly}
        compact
      />
      <EditableCell
        transfer={transfer}
        keyToUpdate="sourceWell"
        handleUpdateValue={handleUpdateValue}
        isDisabled={isReadonly}
        compact
      />
      <EditableCell
        transfer={transfer}
        keyToUpdate="liquid"
        handleUpdateValue={handleUpdateValue}
        isDisabled={isReadonly}
      />
      <TableCell>
        <PolicyParameter
          value={transfer.policy}
          onChange={updatePolicy}
          isDisabled={isReadonly}
        />
      </TableCell>
      <EditableCell
        transfer={transfer}
        keyToUpdate="transferVolume"
        handleUpdateValue={handleUpdateValue}
        volumeUnit={transferVolumeUnit}
        isDisabled={isReadonly}
        compact
      />
      <EditableCell
        transfer={transfer}
        keyToUpdate="destinationWell"
        handleUpdateValue={handleUpdateValue}
        isDisabled={isReadonly}
        compact
      />
      <TableCell className={classes.actionBtns} align="center">
        {/* We need at least one transfer to render the UI, because the whole UI
        is rendered from LiquidTransfer list. Here, we prevent users from
        deleting a transfer if it's the only one. */}
        {allTransfers.length >= 2 && (
          <Fade in={showActionBtns} mountOnEnter>
            <IconButton onClick={handleDeleteTransfer} color="primary" size="large">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Fade>
        )}
      </TableCell>
    </TableRow>
  );
});

type EditableCellProps = {
  transfer: LiquidTransfer;
  keyToUpdate: KeyToUpdate;
  handleUpdateValue: (keyToUpdate: KeyToUpdate, newValue: CellValue) => void;
  volumeUnit?: string;
  // Shrink cell that don't need much space (e.g. well positions)
  compact?: boolean;
  isDisabled: boolean;
};

const EditableCell = React.memo(function EditableCell({
  transfer,
  keyToUpdate,
  handleUpdateValue,
  volumeUnit,
  compact,
  isDisabled,
}: EditableCellProps) {
  const classes = useStyles();
  const updateValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      debouncedEditRowValueEventLog(keyToUpdate);
      const newValue = e.target.value;

      if (volumeUnit) {
        const newAmount: Measurement = {
          value: Number(newValue),
          unit: volumeUnit,
        };
        handleUpdateValue(keyToUpdate, newAmount);
      } else {
        handleUpdateValue(keyToUpdate, newValue);
      }
    },
    [handleUpdateValue, keyToUpdate, volumeUnit],
  );

  const defaultValue = volumeUnit
    ? (transfer[keyToUpdate] as Measurement).value
    : transfer[keyToUpdate];

  return (
    <TableCell>
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        className={cx({ [classes.compactCell]: !!compact })}
      >
        <Grid item>
          <TextField
            variant="standard"
            defaultValue={defaultValue}
            onChange={updateValue}
            disabled={isDisabled}
          />
        </Grid>
        {volumeUnit ? (
          <Grid item className={classes.volumeUnit}>
            {volumeUnit}
          </Grid>
        ) : undefined}
      </Grid>
    </TableCell>
  );
});

const useStyles = makeStylesHook({
  transferVolumeCell: {
    display: 'flex',
    alignItems: 'center',
  },
  wellCell: {
    width: '50%',
  },
  actionBtns: {
    // Remove padding so that icon fits the row without breaking `dense` layout
    padding: 0,
  },
  orderCell: {
    // Prevent numbers to split in two lines
    whiteSpace: 'nowrap',
  },
  compactCell: {
    width: '4rem',
  },
  volumeUnit: { minWidth: '1.5rem' },
  incompleteTransfer: {
    opacity: 0.5,
  },
  ...zebraRowsStyles,
});
